import React, { lazy, Suspense, useEffect } from "react";
import { createCitizensFromUsersData, getCitizensFromUsersDataCount, updateAllUsersDocuments, tempMethod } from "../../API/api";

const HomeMap = lazy(() => import("./HomeMap"));
const Vehicle = lazy(() => import("./vehicle"));
const Complaint = lazy(() => import("./complaints"));
const Task = lazy(() => import("./tasks"));
const Establishment = lazy(() => import("./household"));
const Checkpoint = lazy(() => import("./checkpoints"));
const Staff = lazy(() => import("./staff"));
const Ctpt = lazy(() => import("./ctpt"));
const Feedback = lazy(() => import("./feedback"));

export default function Home(props) {

    useEffect(() => {
        // tempMethod();
        // getCitizensFromUsersDataCount();
        // createCitizensFromUsersData();
        // getCitizensFromUsersDataCount();
        // if(window.localStorage.mun == "chatrapur"){
        //     updateAllUsersDocuments();
        // }
        
    }, [])

    const containerStyle = {
        backgroundColor: "#eee",
        width: "100%",
        height: "105vh",
        display: "flex",
        flexWrap: "wrap", // Allow wrapping
    };

    const leftColumnStyle = {
        width: "60%",
        height: "100%",
        padding: "1% 2%",
        display: "flex",
        flexDirection: "column",
    };

    const rightColumnStyle = {
        width: "40%",
        height: "100%",
        paddingRight: "2%",
        paddingTop: "1%",
        display: "flex",
        flexDirection: "column",
    };

    const itemStyle = {
        width: "100%",
        paddingBottom: "2%",
        paddingTop: "2%",
    };

    const flexItemStyle = {
        display: "flex",
        width: "100%",
        height: "20%",
    };

    const innerFlexItemStyle = {
        width: "100%",
        height: "100%",
        paddingRight: "1%",
    };

    return (
        <div style={containerStyle}>
            <div style={leftColumnStyle}>
                <div style={{ ...itemStyle, height: "64%" }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <HomeMap parentData={props} />
                    </Suspense>
                </div>
                <div style={{ ...itemStyle, height: "18%" }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Establishment parentData={props} />
                    </Suspense>
                </div>
                <div style={flexItemStyle}>
                    <div style={innerFlexItemStyle}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Ctpt parentData={props} />
                        </Suspense>
                    </div>
                    <div style={innerFlexItemStyle}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Feedback parentData={props} />
                        </Suspense>
                    </div>
                    <div style={{ width: "100%", height: "100%" }}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Checkpoint parentData={props} />
                        </Suspense>
                    </div>
                </div>
            </div>
            <div style={rightColumnStyle}>
                <div style={{ ...itemStyle, height: "25%", backgroundColor: "white", borderRadius: 10 }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Vehicle parentData={props} />
                    </Suspense>
                </div>
                <div style={{ ...itemStyle, height: "25%" }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Complaint parentData={props} />
                    </Suspense>
                </div>
                <div style={{ ...itemStyle, height: "25%" }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Task parentData={props} />
                    </Suspense>
                </div>
                <div style={{ ...itemStyle, height: "25%" }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Staff parentData={props} />
                    </Suspense>
                </div>
            </div>
            <style>
                {`
                    @media (max-width: 1024px) {
                        div[style*="flex-direction: column"] {
                            width: 100% !important;
                        }
                    }
                `}
            </style>
        </div>
    );
};
