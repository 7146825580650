import * as firebase from "firebase";
import firebaseApp from "./base";
import { FormatDate, Encode, DateFormat } from "../Components/constants";
import googleMapReact from "google-map-react";
import { GoogleMap } from "react-google-maps";
import moment from "moment";
import { message } from "antd";
import { v4 as uuidv4 } from 'uuid';

var CryptoJS = require("crypto-js");
const HASH_KEY = "6fb74b35af6ce0";

const firebaseAuth = firebaseApp.auth();
const FieldPath = firebase.firestore();
const db = firebase.firestore();
const mun = window.localStorage.mun;

export { firebaseAuth };
export { db };
export { mun };

export function uploadImage(file) {
    let reader = new FileReader();
    const storageRef = firebase.storage().ref();
    let epochTime = new Date().getTime();
    const path = mun + "/Broadcast/" + epochTime + ".jpg";
    const imgRef = storageRef.child(path);
    return imgRef.put(file);
}

// export function updateSaathiThumbnail(imageUrl, authID) {
//     console.log("imageUrl: ", imageUrl);
//     let myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     myHeaders.append("apy-token", "APY0Bv3kyJRXa5fnNbBxTzGSTHFxFoWpveEMQbRlqrYiA3zlpw5fOgCK6awfstx9L1nakOLqhsY17T");

//     let raw = JSON.stringify({
//         "url": imageUrl
//     });

//     let requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: raw,
//         redirect: 'follow'
//     };

//     const storageRef = firebase.storage().ref();
//     const folderRef = storageRef.child("saathiThumbnailImage/" + authID);

//     const setSaathiThumbnailref = db.collection("mun").doc(window.localStorage.mun).collection("saathi").doc(authID)

//     folderRef.listAll()
//         .then((res) => {
//             if (res.items.length === 0) {
//                 if (imageUrl) {
//                     fetch("https://api.apyhub.com/generate/image/thumbnail/url/file?url=" + encodeURIComponent(imageUrl) + "&width=50&height=50", requestOptions)
//                         .then(response => response.arrayBuffer())
//                         .then(buffer => {
//                             const base64 = Buffer.from(new Uint8Array(buffer)).toString('base64');
//                             const imageRef = "saathiThumbnailImage/" + authID + "/" + new Date().getTime() + '.jpg';
//                             const reference = storageRef.child(imageRef);
//                             return reference.putString(base64, 'base64');
//                         })
//                         .then(snapshot => snapshot.ref.getDownloadURL())
//                         .then(downloadUrl => {
//                             console.log("Image uploaded and download URL:", downloadUrl);
//                         })
//                         .catch(error => console.log('Error uploading image:', error));
//                 } else {
//                     console.log("Image not available for ID " + authID);
//                 }
//             } else {
//                 console.log("Thumbnail already available");
//             }
//         })
//         .catch(error => console.log('Error checking folder:', error));

// }

export function deleteThumbnail(id) {
    // Reference to the saathi document
    const setSaathiThumbnailref = db.collection("mun").doc(window.localStorage.mun).collection("saathi").doc(id);

    // Use the get() method to fetch the document data
    setSaathiThumbnailref.get().then((doc) => {
        if (doc.exists) {
            // Check if the document contains a thumbnailUrl field
            if (doc.data().thumbnailUrl) {
                // Delete the thumbnailUrl field
                return setSaathiThumbnailref.update({
                    thumbnailUrl: firebase.firestore.FieldValue.delete()
                }).then(() => {
                    console.log("ThumbnailUrl deleted successfully.");
                }).catch((error) => {
                    console.error("Error deleting thumbnailUrl: ", error);
                });
            } else {
                console.log("No thumbnailUrl to delete.");
            }
        } else {
            console.log("Document not found.");
        }
    }).catch((error) => {
        console.error("Error getting document: ", error);
    });
}


// export function updateSaathiThumbnail(imageUrl, authID) {
//     let myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     myHeaders.append("apy-token", "APY0Bv3kyJRXa5fnNbBxTzGSTHFxFoWpveEMQbRlqrYiA3zlpw5fOgCK6awfstx9L1nakOLqhsY17T");

//     let raw = JSON.stringify({
//         "url": imageUrl
//     });

//     let requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: raw,
//         redirect: 'follow'
//     };

//     const storageRef = firebase.storage().ref();
//     const folderRef = storageRef.child("saathiThumbnailImage/" + authID);

//     const setSaathiThumbnailref = db.collection("mun").doc(window.localStorage.mun).collection("saathi").doc(authID);

//     folderRef.listAll()
//         .then((res) => {
//             if (res.items.length === 0) {
//                 if (imageUrl) {
//                     fetch("https://api.apyhub.com/generate/image/thumbnail/url/file?url=" + encodeURIComponent(imageUrl) + "&width=100&height=100", requestOptions)
//                         .then(response => response.arrayBuffer())
//                         .then(buffer => {
//                             const base64 = Buffer.from(new Uint8Array(buffer)).toString('base64');
//                             const imageRef = "saathiThumbnailImage/" + authID + "/" + new Date().getTime() + '.jpg';
//                             const reference = storageRef.child(imageRef);
//                             return reference.putString(base64, 'base64');
//                         })
//                         .then(snapshot => snapshot.ref.getDownloadURL())
//                         .then(downloadUrl => {
//                             console.log("Image uploaded and download URL: ", downloadUrl);
//                             // Set the thumbnailUrl in the database
//                             setSaathiThumbnailref.update({
//                                 thumbnailUrl: downloadUrl
//                             }).then(() => {
//                                 console.log("Thumbnail URL added to the database.");
//                             }).catch(error => console.log('Error adding thumbnail URL to the database:', error));
//                         })
//                         .catch(error => console.log('Error uploading image:', error));
//                 } else {
//                     console.log("Image not available for ID " + authID);
//                 }
//             } else {
//                 console.log("Thumbnail already available for ID " + authID);
//             }
//         })
//         .catch(error => console.log('Error checking folder:', error));
// }


export async function createSaathiThumbnail(imageUrl, authID) {
    // Check if imageUrl or authID is null or undefined
    if (!imageUrl || !authID) {
        console.log("Invalid imageUrl or authID. Cannot create thumbnail.");
        return null;
    }
    let returnedUrl = "";
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("apy-token", "APY0Bv3kyJRXa5fnNbBxTzGSTHFxFoWpveEMQbRlqrYiA3zlpw5fOgCK6awfstx9L1nakOLqhsY17T");

    let raw = JSON.stringify({
        "url": imageUrl
    });

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const storageRef = firebase.storage().ref();
    const setSaathiThumbnailref = db.collection("mun").doc(window.localStorage.mun).collection("saathi").doc(authID);

    try {
        if (imageUrl) {
            const response = await fetch("https://api.apyhub.com/generate/image/thumbnail/url/file?url=" + encodeURIComponent(imageUrl) + "&width=100&height=100", requestOptions);

            if (response.status === 429) {
                // Too Many Requests - Implement rate limiting or retry logic here
                console.log("Too Many Requests.");
                return;
            }

            const buffer = await response.arrayBuffer();

            if (!buffer) {
                return;
            }

            const base64 = Buffer.from(new Uint8Array(buffer)).toString('base64');
            const imageRef = "saathiThumbnailImage/" + authID + "/" + new Date().getTime() + '.jpg';
            const reference = storageRef.child(imageRef);
            const snapshot = await reference.putString(base64, 'base64');
            const downloadUrl = await snapshot.ref.getDownloadURL();

            await setSaathiThumbnailref.set({
                thumbnailUrl: downloadUrl
            }, { merge: true });

            returnedUrl = downloadUrl;
        } else {
            console.log("Image not available for ID " + authID);
        }
    } catch (error) {
        console.log('Error uploading image:', error);
    }

    return returnedUrl;
}


export async function modifySaathiThumbnail(imageUrl, authID) {
    try {
        await deleteSaathiThumbnail(authID);

        let data = await createSaathiThumbnail(imageUrl, authID);
        return data || imageUrl;
    } catch (error) {
        console.error("Error:", error);
        return imageUrl;
    }
}

export function updateSaathiImageFromPending(id, imageURL, thumbnailURL) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(id)
        .set({
            imageUrl: imageURL,
            thumbnailUrl: thumbnailURL,
        },
            { merge: true });
}

// export function setSaathiPendingAttendance(date) {
//     const collectionName = "pendingAttendance"; // Use the correct collection name here
//     // const collectionName = "pendingAttendace"; // Use the correct collection name here
//     return db
//         .collection("mun")
//         .doc(window.localStorage.mun)
//         .collection("saathiGeo")
//         .doc(date)
//         .collection(collectionName)
//         .get();
// }



export async function deleteSaathiThumbnail(authID) {
    const storageRef = firebase.storage().ref();
    const folderRef = storageRef.child("saathiThumbnailImage/" + authID);

    try {
        const res = await folderRef.listAll();

        if (res.items.length > 0) {
            const promises = res.items.map(async (item) => await item.delete());
            await Promise.all(promises);
        }

    } catch (error) {
        console.log('Error checking folder or deleting files:', error);
    }
}



export function updateSaathiThumbnail(imageUrl, authID) {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("apy-token", "APY0Bv3kyJRXa5fnNbBxTzGSTHFxFoWpveEMQbRlqrYiA3zlpw5fOgCK6awfstx9L1nakOLqhsY17T");

    let raw = JSON.stringify({
        "url": imageUrl
    });

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const storageRef = firebase.storage().ref();
    const folderRef = storageRef.child("saathiThumbnailImage/" + authID);

    const setSaathiThumbnailref = db.collection("mun").doc(window.localStorage.mun).collection("saathi").doc(authID);

    folderRef.listAll()
        .then((res) => {
            if (res.items.length === 0) {
                if (imageUrl) {
                    fetch("https://api.apyhub.com/generate/image/thumbnail/url/file?url=" + encodeURIComponent(imageUrl) + "&width=100&height=100", requestOptions)
                        .then(response => {
                            if (response.status === 429) {
                                // Too Many Requests - Implement rate limiting or retry logic here
                                console.log("Too Many Requests.");
                                return;
                            }
                            return response.arrayBuffer();
                        })
                        .then(buffer => {
                            if (!buffer) {
                                return;
                            }

                            const base64 = Buffer.from(new Uint8Array(buffer)).toString('base64');
                            const imageRef = "saathiThumbnailImage/" + authID + "/" + new Date().getTime() + '.jpg';
                            const reference = storageRef.child(imageRef);
                            return reference.putString(base64, 'base64');
                        })
                        .then(snapshot => snapshot.ref.getDownloadURL())
                        .then(downloadUrl => {
                            console.log("Image uploaded and download URL: ", downloadUrl);
                            // Set the thumbnailUrl in the database
                            setSaathiThumbnailref.update({
                                thumbnailUrl: downloadUrl
                            }).then(() => {
                                console.log("Thumbnail URL added to the database.");
                            }).catch(error => console.log('Error adding thumbnail URL to the database:', error));
                        })
                        .catch(error => console.log('Error uploading image:', error));
                } else {
                    console.log("Image not available for ID " + authID);
                }
            } else {
                console.log("Thumbnail already available for ID " + authID);
            }
        })
        .catch(error => console.log('Error checking folder:', error));
}

export function uploadPicture(file) {
    let reader = new FileReader();
    const storageRef = firebase.storage().ref();
    let epochTime = new Date().getTime();
    const path = mun + "/Broadcast/" + epochTime + ".jpg";
    const imgRef = storageRef.child(path);
    return imgRef.put(file);
}

export function uploadApplication(file, selectedTab) {
    const storageRef = firebase.storage().ref();
    const path = `/builds/${mun}/${selectedTab}/` + file?.name;
    const imgRef = storageRef.child(path);
    return imgRef.put(file);
}





export function uploadTempImage(file) {
    let reader = new FileReader();
    const storageRef = firebase.storage().ref();
    let epochTime = new Date().getTime();
    const path = mun + "/Temp/" + epochTime + ".jpg";
    const imgRef = storageRef.child(path);
    return imgRef.put(file);
}

export function uploadTempExcel(file) {
    let reader = new FileReader();
    const storageRef = firebase.storage().ref();
    let epochTime = new Date().getTime();
    const path = mun + "/Temp/" + epochTime + ".xlsx";
    const xlsxRef = storageRef.child(path);
    return xlsxRef.put(file);
}

const getSaathiRef = () => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
}

export function fetchTrackRequest(rId) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("trackRequest")
        .where("rId", "==", rId)
        .get();
}

export function getAllUsers() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("console_users")
        .get();
}

export async function fetchAndSave_report_checkpoints(newGeoHash, newObject) {
    console.log("in fetchAndSave_report_checkpoints");
    const mun = window.localStorage.mun;
    const currentDate = new Date();
    const dd = String(currentDate.getDate()).padStart(2, '0');
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0');
    const yyyy = currentDate.getFullYear();
    const date_formatted = `${dd}-${mm}-${yyyy}`;
    const docRef = db.collection(`mun/${mun}/report_checkpoints`).doc(date_formatted);
    let checkpoint_doc_data = {};
    try {
        const doc = await docRef.get();
        if (doc.exists) {
            checkpoint_doc_data = doc.data().checkpoint_doc_data || {};
        }
    } catch (error) {
        console.error("Error loading document:", error);
    }
    checkpoint_doc_data[newGeoHash] = newObject;
    try {
        await docRef.set(
            { checkpoint_doc_data },
            { merge: true }
        );
        console.log("Data saved successfully!");
    } catch (error) {
        console.error("Error saving document:", error);
    }
}

export async function fetchAndRemove_report_checkpoints(removeCheckpointId = null) {
    if (!removeCheckpointId) {
        console.log("No checkpoint ID provided for removal.");
        return;
    }

    console.log("in fetchAndRemove_report_checkpoints");
    const mun = window.localStorage.mun;
    const currentDate = new Date();
    const dd = String(currentDate.getDate()).padStart(2, '0');
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0');
    const yyyy = currentDate.getFullYear();
    const date_formatted = `${dd}-${mm}-${yyyy}`;
    const docRef = db.collection(`mun/${mun}/report_checkpoints`).doc(date_formatted);
    let checkpoint_doc_data = {};

    try {
        const doc = await docRef.get();
        if (doc.exists) {
            checkpoint_doc_data = doc.data().checkpoint_doc_data || {};
        }
    } catch (error) {
        console.error("Error loading document:", error);
    }

    // Remove the object with the specified ID
    const updatedCheckpointDocData = Object.keys(checkpoint_doc_data).reduce((acc, key) => {
        const checkpoint = checkpoint_doc_data[key];
        if (checkpoint.checkpoints_id !== removeCheckpointId) {
            acc[key] = checkpoint;
        }
        return acc;
    }, {});

    try {
        await docRef.set(
            { checkpoint_doc_data: updatedCheckpointDocData },
            { merge: true }
        );
        console.log("Checkpoint removed successfully!");
    } catch (error) {
        console.error("Error saving document:", error);
    }
}


export async function tempMethod() {
    console.log("starting temp method")
    try {
        const mun = window.localStorage.mun;
        // const now = moment().tz("Asia/Kolkata");
        // const date_formatted = now.format("DD-MM-YYYY");

        const currentDate = new Date();
        const dd = String(currentDate.getDate()).padStart(2, '0');
        const mm = String(currentDate.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = currentDate.getFullYear();

        const date_formatted = `${dd}-${mm}-${yyyy}`;

        console.log("date_formatted: ", date_formatted, typeof (date_formatted))

        const querySnapshot = await db.collection(`mun/${mun}/geohashCheckpoints`).get();
        let checkpoint_doc_data = {};

        querySnapshot.forEach((doc) => {
            const checkpoints = doc.data();
            const checkpointsData = {
                checkpointsName: checkpoints.checkpoints_name,
                checkpointsType: checkpoints.type,
                ward_id: checkpoints.ward_id,
                checkpoints_id: checkpoints.checkpoints_id,
                lat: checkpoints.position.geopoint.latitude,
                long: checkpoints.position.geopoint.longitude,
                attended: 'no',
            };

            for (let key in checkpointsData) {
                if (!key || !checkpointsData[key]) {
                    console.log('Deleted checkpoint key', key);
                    delete checkpointsData[key];
                }
            }

            const geohash = checkpoints.position['geohash'];
            if (geohash) {
                checkpoint_doc_data[geohash] = checkpointsData;
            } else {
                console.log('No geohash for checkpoint', checkpoints);
            }
        });

        await db.collection(`mun/${mun}/report_checkpoints`).doc(date_formatted).set(
            { checkpoint_doc_data },
            { merge: true }
        );

        console.log('Data updated successfully!');
    } catch (error) {
        console.error('Error updating data:', error);
        // Handle error here, e.g., show an error message to the user
    }
}



export function getConsoleUsers() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("console_users")
        .doc(window.localStorage.cui)
        .get();
}

export function getAllMunUsers() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users")
        .get();
}

export async function updateAllUsersDocuments() {
    console.log("in updateAllUsersDocuments");
    const munId = window.localStorage.mun;

    try {
        const usersCollectionRef = db.collection("mun").doc(munId).collection("users");
        const usersQuerySnapshot = await usersCollectionRef.get();

        usersQuerySnapshot.forEach(async (userDocSnapshot) => {
            const userId = userDocSnapshot.id;
            const userData = userDocSnapshot.data();
            let latitude = userData.LATITUDE != undefined ? userData.LATITUDE.toString() : "0";
            let longitude = userData.LONGITUDE != undefined ? userData.LONGITUDE.toString() : "0";

            await usersCollectionRef.doc(userId).update({
                phoneNumber: userData.MOBILE_NO,
                ward: `WARD ${userData.WARD_NO}`,
                OWNER_NAME: CryptoJS.AES.encrypt(userData.OWNER_NAME || '', HASH_KEY).toString(),
                POSTAL_ADDRESS: CryptoJS.AES.encrypt(userData.POSTAL_ADDRESS || '', HASH_KEY).toString(),
                name: CryptoJS.AES.encrypt(userData.OWNER_NAME || '', HASH_KEY).toString(),
                address: CryptoJS.AES.encrypt(userData.POSTAL_ADDRESS || '', HASH_KEY).toString(),
                areaCode: `WARD ${userData.WARD_NO}`,
                ward_id: `WARD ${userData.WARD_NO}`,
                userType: "user",
                lat: CryptoJS.AES.encrypt(latitude, HASH_KEY).toString(),
                long: CryptoJS.AES.encrypt(longitude, HASH_KEY).toString(),
                LATITUDE: CryptoJS.AES.encrypt(latitude, HASH_KEY).toString(),
                LONGITUDE: CryptoJS.AES.encrypt(longitude, HASH_KEY).toString(),
                aadharNumber: null,
                accuracy: null,
                altitude: null,
                altitudeAccuracy: null,
                cluster_id: null,
                createdTime: null,
                date: null,
                dustBinStatus: null,
                heading: null,
                holdingNo: null,
                houseImage: null,
                latitudeDelta: null,
                longitudeDelta: null,
                municipality: userData.ULB_NAME.toLowerCase(),
                qrCode: null,
                scannedValue: null,
                toiletStatus: null,
            });

            console.log("Document with ID " + userId + " updated successfully!");
        });

        console.log("All documents updated successfully!");
    } catch (error) {
        console.error("Error updating documents:", error);
    }
}


export function userLocation(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users")
        .doc(id)
        .get()
}

export function deleteCitizen(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users")
        .doc(id)
        .delete()
}

export function fetchComplaintsDownload(created_date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
        .where("created_date", "==", created_date)
        .orderBy("createdTime", "desc")
        .get();
}

export function fetchActiveComplaints() {
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    return (
        db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("complaints")
            .where("state", "==", "ACTIVE")
            .orderBy("createdTime", "desc")
            //.where("createdTime", ">=", start)
            .get()
    );
}

export function updateUserRules(id, updateObj, tag_id, pc, pc_id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("console_users")
        .doc(id)
        .update({
            perms: updateObj,
            tag_id: tag_id ? tag_id : [],
            pc: pc || '',
            pc_id: pc_id || ''
        });
}

export const isUserAllowed = async (userId) => {
    let val;
    await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("console_users")
        .doc(userId)
        .get()
        .then(async (snapshot) => {
            val = snapshot.data();
        });
    return val;
};

export function getDeviceInfo(device_id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("devices")
        .where("id", "==", device_id)
        .get();
}

export function getDevices() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("devices")
        .orderBy("id", "asc")
        .get();
}

export function getWardByDevices(ward_id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("devices")
        .where("ward_id", "==", ward_id)
        .orderBy("id", "asc")
        .get();
}

/*export function getDevices() {
  return db
    .collection("mun")
    .doc(window.localStorage.mun)
    .collection("devices")
    .get();
}*/

export function getWardVehicles(ward_id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicles")
        .orderBy("vehicle_name", "asc")
        .get();
}

export async function addDriver(data) {
    try {
        await db.runTransaction(async (t) => {
            let driverRef = db
                .collection("mun")
                .doc(window.localStorage.mun)
                .collection("drivers")
                .doc();
            let deviceRef = db
                .collection("mun")
                .doc(window.localStorage.mun)
                .collection("devices")
                .doc(data.device_id.trim());
            let vehRef = db
                .collection("mun")
                .doc(window.localStorage.mun)
                .collection("vehicles")
                .doc(data.vehicle_doc_id.trim());
            t.set(driverRef, data);
            t.update(deviceRef, {
                driver_name: data.driver_name,
                driver_phone_num: data.phone_num,
            });
            t.update(vehRef, {
                driver_name: data.driver_name,
                driver_phone_num: data.phone_num,
            });
        });

        console.log("Transaction success!");
    } catch (e) {
        console.log("Transaction failure:", e);
    }
    return true;
}

export function getDrivers() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("drivers")
        .get();
}

export function getReports(date, device, ward, type, order) {
    let query = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("reports")
        .where("date", "==", date);
    if (date && device && ward)
        return query.where("device_id", "==", device).get();
    else if (date && ward)
        return query.where("ward_id", "array-contains-any", [ward]).get();
    else if (date && device)
        return query.where("device_id", "==", device).get();
    else if (date && type && order)
        return query.orderBy(type, order).get();
    else if (date && type)
        return query.orderBy(type, "asc").get();
    else if (date)
        return query.orderBy("vehicle_num", "asc").get();
}

const getVehicleReportsRef = () => {
    return db.collection("mun").doc(window.localStorage.mun).collection("reports")
}


export const getReportsOfVehicles = async (datesArray, device) => {
    let query;
    if (!device) {
        query = await getVehicleReportsRef().where("date", "in", datesArray).get();
    } else {
        query = await getVehicleReportsRef()
            .where("date", "in", datesArray)
            .where("device_id", "==", device)
            .get();
    }
    return query;
}


const getVehicleSummaryReportsRef = () => {
    return db.collection("mun").doc(window.localStorage.mun).collection("daily_vehicles_reports");
}

export const getStaffSummaryReportsRef = (summaryData) => {
    return db.collection("mun").doc(window.localStorage.mun).collection("daily_staff_reports");
}




// export const createStaffSummaryReportsRef = async (missingDatesArray, missingData) => {
//     console.log("missingData staff: ", missingData);
//     const munRef = db.collection("mun").doc(window.localStorage.mun);
//     const reportsRef = munRef.collection("daily_staff_reports");
//     function formatDate(inputDate) {
//         const parts = inputDate.split('-');
//         const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
//         return formattedDate;
//     }
//     const snapshot = await reportsRef.get();
//     const existingDates = new Set(snapshot.docs.map(doc => doc.id));
//     const newMissingDatesArray = missingDatesArray.filter(date => !existingDates.has(formatDate(date)));
//     const newMissingData = [];

//     newMissingDatesArray.forEach(date => {
//         const formattedDate = formatDate(date);

//         if (!existingDates.has(formattedDate)) {
//             console.log(`Processing date: ${formattedDate}`);
//             const missingItem = missingData.find(item => item.date === date);
//             if (missingItem) {
//                 const { rows } = missingItem;
//                 newMissingData.push({ date, rows });
//             }
//         } else {
//             console.log(`Date ${formattedDate} already exists in the collection. Skipping.`);
//         }
//     });
//     const batch = db.batch();
//     newMissingData.forEach(({ date, rows }) => {
//         const formattedDate = formatDate(date);
//         const docRef = reportsRef.doc(formattedDate);
//         console.log(`New rows for ${formattedDate}:`, rows);
//         batch.set(docRef, { rows }, { merge: true });
//     });

//     if (newMissingData.length > 0) {
//         // Only commit the batch if there are new missing dates
//         await batch.commit();
//         console.log("Batch committed.");
//     }
//     return reportsRef;
// };

// export const createStaffSummaryReportsRef = async (missingDatesArray, missingData) => {
//     console.log("missingData staff: ", missingData);
//     const munRef = db.collection("mun").doc(window.localStorage.mun);
//     const reportsRef = munRef.collection("daily_staff_reports");

//     function formatDate(inputDate) {
//         const parts = inputDate.split('-');
//         const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
//         return formattedDate;
//     }

//     const snapshot = await reportsRef.get();
//     const existingDates = new Set(snapshot.docs.map(doc => doc.id));
//     const newMissingDatesArray = missingDatesArray.filter(date => !existingDates.has(formatDate(date)));

//     for (const date of newMissingDatesArray) {
//         const formattedDate = formatDate(date);

//         if (!existingDates.has(formattedDate)) {
//             console.log(`Processing date: ${formattedDate}`);
//             const missingItem = missingData.find(item => item.date === date);

//             if (missingItem) {
//                 const { rows } = missingItem;
//                 const docRef = reportsRef.doc(formattedDate);

//                 console.log(`New rows for ${formattedDate}:`, rows);
//                 await docRef.set({ rows }, { merge: true });
//                 console.log(`Document ${formattedDate} updated.`);

//                 // Add a delay if needed before moving to the next iteration
//                 // await new Promise(resolve => setTimeout(resolve, 1000)); // 1-second delay
//             }
//         } else {
//             console.log(`Date ${formattedDate} already exists in the collection. Skipping.`);
//         }
//     }

//     console.log("Writes completed.");
//     return reportsRef;
// };


export const createStaffSummaryReportsRef = async (missingDatesArray, missingData) => {
    console.log("missingData staff: ", missingData);
    const munRef = db.collection("mun").doc(window.localStorage.mun);
    const reportsRef = munRef.collection("daily_staff_reports");

    function formatDate(inputDate) {
        const parts = inputDate.split('-');
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        return formattedDate;
    }

    const snapshot = await reportsRef.get();
    const existingDates = new Set(snapshot.docs.map(doc => doc.id));
    const newMissingDatesArray = missingDatesArray.filter(date => !existingDates.has(formatDate(date)));

    for (const date of newMissingDatesArray) {
        const formattedDate = formatDate(date);

        if (!existingDates.has(formattedDate)) {
            console.log(`Processing date: ${formattedDate}`);
            const missingDataForDate = missingData.filter(item => item.date === date);

            const docRef = reportsRef.doc(formattedDate);
            const existingData = (await docRef.get()).data();

            let newData = existingData || {}; // If document already exists, use existing data

            for (const missingItem of missingDataForDate) {
                const { rows } = missingItem;
                newData = { ...newData, rows }; // Merge rows into newData
            }

            console.log(`New rows for ${formattedDate}:`, newData.rows);
            await docRef.set(newData);
            console.log(`Document ${formattedDate} updated.`);
        } else {
            console.log(`Date ${formattedDate} already exists in the collection. Skipping.`);
        }
    }

    console.log("Writes completed.");
    return reportsRef;
};




// export const createVehicleSummaryReportsRef = async (missingDatesArray, missingData) => {

//     console.log("missingData23456: ", missingData)

//     const munRef = db.collection("mun").doc(window.localStorage.mun);
//     const reportsRef = munRef.collection("daily_vehicles_reports");

//     function formatDate(inputDate) {
//         console.log("in formatDate")
//         const parts = inputDate.split('-');
//         const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
//         return formattedDate;
//     }

//     const snapshot = await reportsRef.get();
//     if (snapshot.empty) {
//         console.log("in snapshot.empty")
//         const batch = db.batch();
//         missingDatesArray.forEach(date => {
//             const docRef = reportsRef.doc(formatDate(date));
//             batch.set(docRef, { /* Initial data for the document, if any */ });
//         });

//         await batch.commit();
//     } else {
//         console.log("in else snapshot.empty")
//         const existingDates = snapshot.docs.map(doc => doc.id);
//         const missingDates = missingDatesArray.filter(date => !existingDates.includes(date));
//         if (missingDates.length > 0) {
//             const batch = db.batch();
//             missingDates.forEach(date => {
//                 const docRef = reportsRef.doc(formatDate(date));
//                 batch.set(docRef, { /* Initial data for the document, if any */ });
//             });
//             await batch.commit();
//         }
//     }

//     for (const missingItem of missingData) {
//         console.log("in for missingdata")
//         const { date, rows } = missingItem;
//         const docRef = reportsRef.doc(formatDate(date));
//         const existingRows = [];
//         const docSnapshot = await docRef.get();
//         if (docSnapshot.exists) {
//             const existingData = docSnapshot.data();
//             if (existingData.rows) {
//                 existingRows.push(...existingData.rows);
//             }
//         }
//         existingRows.push(...rows);
//         console.log("existingRows: ", existingRows)
//         await docRef.set({ rows: existingRows }, { merge: true });
//     }
//     return reportsRef;
// }

export const createVehicleSummaryReportsRef = async (missingDatesArray, missingData) => {

    const munRef = db.collection("mun").doc(window.localStorage.mun);
    const reportsRef = munRef.collection("daily_vehicles_reports");

    function formatDate(inputDate) {
        const parts = inputDate.split('-');
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        return formattedDate;
    }

    try {
        const snapshot = await reportsRef.get();

        if (snapshot.empty) {
            const batch = db.batch();
            missingDatesArray.forEach(date => {
                const docRef = reportsRef.doc(formatDate(date));
                batch.set(docRef, { /* Initial data for the document, if any */ });
            });

            await batch.commit();
        } else {
            const existingDates = snapshot.docs.map(doc => doc.id);
            const missingDates = missingDatesArray.filter(date => !existingDates.includes(date));

            if (missingDates.length > 0) {
                const batch = db.batch();
                missingDates.forEach(date => {
                    const docRef = reportsRef.doc(formatDate(date));
                    batch.set(docRef, { /* Initial data for the document, if any */ });
                });
                await batch.commit();
            }
        }

        for (const missingItem of missingData) {
            const { date, rows } = missingItem;
            const docRef = reportsRef.doc(formatDate(date));
            const existingRows = [];

            try {
                const docSnapshot = await docRef.get();
                const existingData = docSnapshot.data();
                if (existingData && existingData.rows) {
                    existingRows.push(...existingData.rows);
                }
                existingRows.push(...rows);
                try {
                    await docRef.set({ rows: existingRows }, { merge: true });
                } catch (error) {
                    console.error("Error setting document:", error);
                }
            } catch (error) {
                console.error("Error fetching document:", error);
            }
        }
        return reportsRef;
    } catch (error) {
        console.error("Error fetching documents:", error);
        throw error;
    }
}

export const getVehicleSummaryReports = async (datesArray, device) => {
    const docRefs = [];
    for (const date of datesArray) {
        function formatDate(inputDate) {
            const parts = inputDate.split('-');
            if (parts.length === 3) {
                const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
                return formattedDate;
            } else {
                console.error("Invalid date format");
                return null;
            }
        }
        const formattedDate = formatDate(date);
        const docRef = getVehicleSummaryReportsRef().doc(formattedDate);
        docRefs.push(docRef);
    }
    return docRefs;
}



export function getdeviceGeo(device_number) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("device_geo")
        .get();
}

export function getDeviceGeo(fallback) {
    let start;
    let date = new Date();
    if (fallback) {
        start = date.setDate(date.getDate() - 1);
    } else {
        start = date;
    }
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("device_geo")
    //.where("date", "==", FormatDate(start));
}

export function getDeviceGeoOffline() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("device_geo")
        .get();
}

export function getAlertedUsers() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("notification_status")
        .where("date", "==", FormatDate(new Date()))
        .get();
}

export function getDeviceForDate(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("reports")
        .where("date", "==", date);
}

export function getDeviceForVehicle(device_id, date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("reports")
        .where("date", "==", date)
        .where("device_id", "==", device_id);
}

// export function getDeviceForWardVehicle(device_id, date) {
//   // debugger
//   return db
//     .collection('mun')
//     .doc(window.localStorage.mun)
//     .collection('reports')
//     .where('date', '==', date)
//     .where('device_id', '==', device_id)
//     .get()

// }


export function getDeviceForWardVehicle(device_id, date) {

    console.log("device_id: ", device_id)
    console.log("date: ", date)

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection('reports')
        .where('date', '==', date)
        .where('device_id', '==', device_id)
        .get()

};

export function updateDevice(id, updateObj) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("devices")
        .doc(id)
        .update(updateObj);
}

export function deleteDevice(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("devices")
        .doc(id)
        .delete();
}

export function addDevice(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("devices")
        .doc(data.id)
        .set(data);
}

export function addSmartbins(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("smartbins")
        .doc()
        .set(data);
}

export function fetchSmartbinsData() {
    return db
        .collection('mun')
        .doc(window.localStorage.mun)
        .collection('smartbins')
        .get()
}

export function removeSmartbin(id) {
    return db
        .collection('mun')
        .doc(window.localStorage.mun)
        .collection('smartbins')
        .doc(id)
        .delete()
}

export function addRouteGorup(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("route_group")
        .doc()
        .set(data)
}

export function fetchRouteGorupData() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("route_group")
        .get()
}

export function addPlaces(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("places")
        .doc()
        .set(data);
}

export function fetchPlaceData() {
    return db
        .collection('mun')
        .doc(window.localStorage.mun)
        .collection('places')
        .get()
}

export function removePlace(id) {
    return db
        .collection('mun')
        .doc(window.localStorage.mun)
        .collection('places')
        .doc(id)
        .delete()
}

export const addCheckpoints = async (data) => {
    const doc_ref = await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("checkpoints")
        .add(data)
    return doc_ref.id;
}

export function removeCheckpoint(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("checkpoints")
        .doc(id)
        .delete();
}
export async function removeCheckpointRfid(id) {
    try {
        const querySnapshot = await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("rfidDevices")
            .where('checkgateName', '==', id)
            .get();

        querySnapshot.forEach(async (doc) => {
            await doc.ref.update({
                checkgateName: '',
                checkgateId: ''
            });
        });

        console.log('Documents updated successfully.');
    } catch (error) {
        console.error('Error updating documents:', error);
    }
}


export function getCheckpoints() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("checkpoints")
        .get();
}
export function getCheckGates() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("checkpoints")
        .where("ward_id","==","0")
        .get();
}
export function fetchCheckpointsInfo() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("geohashCheckpoints")
        .get();
}

export function fetchCheckgate() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("checkpoints")
        .where("ward_id", "==", "0")
        .get();
}

export function getDustbinApi() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("checkpoints")
        .where("tyep", "==", "Dustbin")
        .get();
}

export function saveTripConfig(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicleTrips")
        .doc()
        .set(data);
}

export function isCheckpointExists(name) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("checkpoints")
        .where("name", "==", name)
        .get();
}

export function isManualEntryExist(name) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("pcdaily")
        .doc(name)
        .get();
}

export function addManualyEntry(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("pc")
        .doc(data.pc_id)
        .collection("data")
        .doc(data.device_id + "-" + data.date)
        .set(data);
}

export function addOutFlowEntry(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("pc")
        .doc(data.pc_id)
        .collection("outflow")
        .doc(data.date)
        .set(data);
}


export function updateManualyEntry(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("pc")
        .doc(data.pc_id)
        .collection("data")
        .doc(data.id)
        .set({ trips: data.trips }, { merge: true });
}


export function isProcessingCenterExists(name) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("pc")
        .doc(name)
        .get();
}

export function addProcessingUnit(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("pc")
        .doc()
        .set(data);
}

export function editProcessingCenter(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("pc")
        .doc(data.id)
        .set({
            name: data.name,
            select_type: data.select_type,
            landmark: data.landmark,
        }, { merge: true });
}

export function deletePc(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("pc")
        .doc(id)
        .delete()

}

export function getProcessingCenter() {

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("pc")
        .get()

}

export function getManual(date, id) {
    //  console.log(date)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("pc")
        .doc(id)
        .collection("data")
        .where("date", "==", date)
        .get()

}


export function getOutFlow(date, id) {
    // console.log(date)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("pc")
        .doc(id)
        .collection("outflow")
        .where("date", "==", date)
        .get()

}

export function getCheckpointInfo(name) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("checkpoints")
        .where("name", "==", name)
        .get();
}

export async function saveSettingsMsg(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .set({ picture: data.picture }, { merge: true });
}

export async function getSettingsMsg() {
    let val = {};
    await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .get()
        .then(async (snapshot) => {
            let data = snapshot.data();
            val = data.picture;
        });

    return val;
}

export function saveBroadcastMsg(data) {
    console.log("data saveBroadcastMsg: ", data);

    // Ensure data, data.ward_id, and data.staff_tokens are defined
    const cleanedData = {
        ...data,
        ward_id: (data.ward_id || []).map(item => item.toLowerCase()),
        staff_tokens: (data.staff_tokens || []).map(staff => ({
            ...staff,
            tokens: (staff.tokens || []).filter(token => token !== "")
        }))
    };

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("broadcast")
        .add(cleanedData);
}


export function updateClusterData(id, cluster_arr, updateObj, docId, cluster_wards) {
    let arr = [...cluster_arr];
    let wards_arr = [...cluster_wards, updateObj?.ward_id]
    if (updateObj) {
        arr.push({
            name: updateObj.name || '',
            latlong: { lat: updateObj.lat, long: updateObj.lng } || {},
            id: docId,
            ward_id: updateObj?.ward_id || ""
        })
    }
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("cluster")
        .doc(id)
        .update({ checkpoints: arr, wards: wards_arr });
}

export function addNewCluster(obj) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("cluster")
        .doc()
        .set(obj);

}

export function getClusters() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("cluster")
        .get()

}
export function getRfidDeviceData() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("rfidDevices")
        .get()

}
export function addVehicleSummary(obj) {
    // console.log("obj", obj)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicle_summary")
        .doc()
        .set(obj);
}

export function updateVehicleSummary(obj, id) {
    // console.log("obj", obj, "id", id)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicle_summary")
        .doc(id)
        .set(obj);
}

export function getVehicleSummary() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicle_summary")
        .get()
}

export function getVehicleSummaryNotify(date) {
    // console.log("api_date", date)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicle_summary_notif")
        .doc(date)
        .collection("alerts")
        .get()
}
export function getBroadcast() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("broadcast")
        .orderBy("date", "desc")
        .get();
}

export function deleteBroadcast(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("broadcast")
        .doc(id)
        .delete()
}

export function getDatedBroadcast(startDate, endDate) {
    return (
        db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("broadcast")
            // .orderBy('date')
            .where("date", ">=", DateFormat(startDate))
            .where("date", "<=", DateFormat(endDate))

            .get()
    );
}

export function isWardExists(ward_id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("wards")
        .doc(ward_id)
        .get();
}

export function addWard(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("wards")
        .doc()
        .set(data);
}

export function addLatLngInWard(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("wards")
        .doc(data.d_id)
        .set(data, { merge: true });
}

export function deleteWard(d_id) {
    // console.log('f',d_id)
    //  debugger
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("wards")
        .doc(d_id)
        .delete()
}


export function deletegeofence(id) {

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("wards")
        .doc(id)
        .set({ Coordinates: [] }, { merge: true });
}


export function updateWard(id, updateObj) {
    // console.log("update == ", id, updateObj)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("wards")
        .doc(id)
        .update(updateObj);
}

export function saveWardFence(id, updateObj) {
    console.log("id: ", id);
    console.log("updateObj: ", updateObj);

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("wards")
        .doc(id)
        .update(updateObj)
        .then(() => {
            alert("Ward fence updated successfully!");
        })
        .catch(error => {
            console.error("Error updating ward fence: ", error);
            alert("Error updating ward fence. Please try again later.");
        });
}


export function isTagExists(ward_id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tags")
        .doc(ward_id)
        .get();
}
export function isRfidTagExists() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("rfidTags")
        .get();
}

export function AddRfidTag(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("rfidTags")
        .doc(data.id)
        .set(data);
}

export function deleteRfidTag(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("rfidTags")
        .doc(id)
        .delete();
}

export function updateRfidTag(updateObj) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("rfidTags")
        .doc(updateObj.id)
        .update(updateObj);
}

export function AddTag(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tags")
        .doc(data.id)
        .set(data);
}

export function deleteTag(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tags")
        .doc(id)
        .delete();
}

export function updateTag(id, updateObj) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tags")
        .doc(id)
        .update(updateObj);
}
export function addRfidDevice(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("rfidDevices")
        .doc(data.id)
        .set(data);
}
export function deleteRfidDevice(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("rfidDevices")
        .doc(id)
        .delete();
}
export function removeCheckpointRfidData(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("checkpoints")
        .where("rfidDeviceId", "==", id)
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.update({ rfidDeviceId: "",rfidDeviceName: "" })
                .then(() => {
                  console.log("Document successfully updated!");
                })
                .catch(error => {
                  console.error("Error updating document: ", error);
                });
            });
          })
          .catch(error => {
            console.error("Error getting documents: ", error);
          });
}

export function removeVehicleRfidData(id) {
    console.log("removeVehicleRfidData")
    return db
    .collection("mun")
    .doc(window.localStorage.mun)
    .collection("vehicles")
    .where("rfidTagId", "==", id)
    .get()
    .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.update({ rfidTagId: "" ,rfidTagName: "" })
            .then(() => {
              console.log("Document successfully updated!");
            })
            .catch(error => {
              console.error("Error updating document: ", error);
            });
        });
      })
      .catch(error => {
        console.error("Error getting documents: ", error);
      });
}
export function updateVehicleRfidTag(data) {
    console.log('asdfgh',data)
    return new Promise((resolve, reject) => {
        if (!data.vehicleID) {
            removeVehicleRfidData(data.id)
                .then(() => {
                    console.log("Vehicle RFID data removed successfully");
                    resolve("rfidDataRemoved");
                })
                .catch(error => {
                    console.error("Error removing vehicle RFID data: ", error);
                    reject(error);
                });
        } else {
            removeVehicleRfidData(data.id)
                .then(() => {
                    return db.collection("mun")
                        .doc(window.localStorage.mun)
                        .collection("vehicles")
                        .where("vehicle_id", "==", data.vehicleID)
                        .get();
                })
                .then(querySnapshot => {
                    let vehicleAssignedToRfid = false;
                    querySnapshot.forEach(doc => {
                        if (doc.data().rfidTagId) {
                            vehicleAssignedToRfid = true;
                        }
                    });

                    if (vehicleAssignedToRfid) {
                        console.log("vehicleAlreadyAssignedToRfid");
                        resolve("vehicleAlreadyAssignedToRfid");
                        return;
                    }
                    const promises = [];
                    querySnapshot.forEach(doc => {
                        console.log("Updating document:", doc.id, "with data:", data);
                        const updatePromise = doc.ref.update({ rfidTagId: data.id, rfidTagName: data.name });
                        promises.push(updatePromise);
                    });

                    return Promise.all(promises);
                })
                .then(() => {
                    console.log("All documents successfully updated!");
                    resolve("updated");
                })
                .catch(error => {
                    console.error("Error processing request: ", error);
                    reject(error);
                });
        }
    });
}


export function AddVehicleRfidData(data) {
    console.log('sdfgh',data)
    return new Promise((resolve, reject) => {
        db.collection("mun")
            .doc(window.localStorage.mun)
            .collection("vehicles")
            .where("vehicle_id", "==", data.vehicleID)
            .get()
            .then(querySnapshot => {
                    const promises = [];
                    querySnapshot.forEach(doc => {
                        console.log(doc.ref,doc)
                        const updatePromise = doc.ref.update({ rfidTagName: data.name, rfidTagId: data.id });
                        promises.push(updatePromise);
                    });
                    Promise.all(promises)
                        .then(() => {
                            console.log("All documents successfully updated!");
                            resolve("updated");
                        })
                        .catch(error => {
                            console.error("Error updating documents: ", error);
                            reject(error);
                        });
                
            })
            .catch(error => {
                console.error("Error getting documents: ", error);
                reject(error);
            });
    });
}

export function updateCheckpointRfidDevice(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("checkpoints")
        .where("rfidDeviceId", "==", data.id)
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.update({ rfidDeviceName: data.name })
                .then(() => {
                  console.log("Document successfully updated!");
                })
                .catch(error => {
                  console.error("Error updating document: ", error);
                });
            });
          })
          .catch(error => {
            console.error("Error getting documents: ", error);
          });
}
export function updateRfidDevice(id, updateObj) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("rfidDevices")
        .doc(id)
        .update(updateObj);
}
export function getCitizen() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users")
        .get();
}

const ResidentsData = (snapShotData) => {
    let r_data = []
    snapShotData.forEach((doc) => {
        let usersData = doc.data();

        const id = doc.id;
        if (window.localStorage.mun == 'buxar' || window.localStorage.mun == 'adityapur' || window.localStorage.mun == "jagdalpur" || window.localStorage.mun == "buguda") {
            let key = '6fb74b35af6ce0';
            // to decrypt long
            const lat_decrypt = CryptoJS.AES.decrypt(usersData.lat, key);
            const lat = Number((lat_decrypt.toString(CryptoJS.enc.Utf8)));
            // to decrypt long
            const long_decrypt = CryptoJS.AES.decrypt(usersData.long, key);
            const long = Number((long_decrypt.toString(CryptoJS.enc.Utf8)));
            // to decrypt name
            const name_decrypt = CryptoJS.AES.decrypt(usersData.name, key);
            const name = name_decrypt.toString(CryptoJS.enc.Utf8);
            // to decrypt address
            const address_decrypt = CryptoJS.AES.decrypt(usersData.address, key);
            const address = address_decrypt.toString(CryptoJS.enc.Utf8);
            const aadar = usersData.aadharNumber != undefined ? 'yes' : 'no'
            usersData.d_id = id
            usersData.lat = lat;
            usersData.long = long;
            usersData.name = name;
            usersData.address = address;
            usersData.qrCode = usersData.qrCode != undefined ? usersData.qrCode == null ? '' : usersData.qrCode : '';
            usersData.newDate = usersData.createdTime != undefined ? moment.unix(usersData.createdTime.seconds).format("DD-MM-YYYY HH:mm") : '';
            r_data.push(usersData);
        } else {
            usersData.d_id = id
            usersData.newDate = usersData.createdTime != undefined ? moment.unix(usersData.createdTime.seconds).format("DD-MM-YYYY HH:mm") : '';
            r_data.push(usersData);
        }
    })
    return r_data;
}


const FilterResidentsData = (snapShotData) => {
    let r_data = []
    snapShotData.forEach((doc) => {
        let newObj = {};
        let usersData = doc.data();
        let wardid = "WARD"
        const id = doc.id;
        console.log("usersData: ", usersData)
        if (window.localStorage.mun == 'buxar' || window.localStorage.mun == 'adityapur' || window.localStorage.mun == "jagdalpur" || window.localStorage.mun == "buguda" || window.localStorage.mun == "dehri") {
            let key = '6fb74b35af6ce0';
            // to decrypt name , lat, long
            const name_decrypt = CryptoJS.AES.decrypt(usersData.name || usersData.OWNER_NAME, key);
            const lat_decrypt = CryptoJS.AES.decrypt(usersData.lat || usersData.LATITUDE, key);
            const long_decrypt = CryptoJS.AES.decrypt(usersData.long || usersData.LONGITUDE, key);
            if (usersData.name.length > 20 && !usersData.name.includes(' ')) {
                newObj.name = name_decrypt.toString(CryptoJS.enc.Utf8);
            } else {
                newObj.name = usersData.name || usersData.OWNER_NAME || null;
            }
            newObj.lat = lat_decrypt.toString(CryptoJS.enc.Utf8);
            newObj.long = long_decrypt.toString(CryptoJS.enc.Utf8);
        } else {
            newObj.name = usersData.name || usersData.OWNER_NAME || null;
            newObj.lat = usersData.lat || usersData.LATITUDE || null;
            newObj.long = usersData.long || usersData.LONGITUDE || null;
        }
        newObj.boardedBy = usersData.boardedBy || null;
        newObj.phoneNumber = usersData.phoneNumber || usersData.MOBILE_NO || null;
        newObj.ward = usersData.ward || `${wardid} ${usersData.WARD_NO}` || null;
        newObj.authUid = usersData.authUid || id || null;
        newObj.qrCode = usersData.qrCode != undefined ? usersData.qrCode == null ? '' : usersData.qrCode : '';
        newObj.houseImage = usersData.houseImage || null;
        newObj.tenantList = usersData.tenantList || [];

        r_data.push(newObj);

    })
    return r_data;
}

const FilterResidentsDataFromUsers = (snapShotData) => {
    let r_data = []
    snapShotData.forEach((doc) => {
        r_data.push(doc.data());
    })
    return r_data;
}

const FilterResidentsDataFromUsersDemo = (snapShotData) => {
    let r_data = [];
    snapShotData.forEach((doc) => {
        const data = doc.data();
        if (data.boardedBy != "") {
            r_data.push(data);
        }
    });
    return r_data;
}

const FilterMobileUserDataFromUsersDemo = (snapShotData) => {

    let r_data = [];
    snapShotData.forEach((doc) => {
        const data = doc.data();
        if (data.boardedBy == "") {
            r_data.push(data);
        }
    });
    return r_data;
}

export const getBatchwiseResidents = async (lastDocument, batchSize) => {

    let query = db.collection("mun").doc(window.localStorage.mun).collection("users");
    if (lastDocument) {
        query = query.startAfter(lastDocument);
    }
    query = query.limit(batchSize);
    const snapshot = await query.get();
    return snapshot;
}

export const getResidentDoc = (obj) => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users")
        .doc(obj.authUid)
        .get();
}

export const fetchResidentsCount = () => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("counts")
        .doc("residentsCount")
        .get();

}

export const sizeOfresidents = async () => {
    let dataSource = []
    await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users")
        .get()
        .then((snapshot) => {
            dataSource = FilterResidentsData(snapshot);
        })
    return dataSource;
}

export const fetchNullDocsHouseholds = async () => {
    try {
        const munDocRef = db.collection("mun").doc(window.localStorage.mun);
        const usersCollectionRef = munDocRef.collection("households");
        const querySnapshot = await usersCollectionRef.where("user_id", "==", null).get();
        querySnapshot.forEach(async (doc) => {
            await usersCollectionRef.doc(doc.id).update({
                user_id: doc.id
            });
        });
        console.log(`Total Count of Docs with user_id: null: ${querySnapshot.size}`);
    } catch (error) {
        console.error("Error fetching or updating documents:", error);
    }
};

export const deleteDuplicateDDN = async () => {
    try {
        const munDocRef = db.collection("mun").doc(window.localStorage.mun);
        const usersCollectionRef = munDocRef.collection("users");
        const usersSnapshot = await usersCollectionRef.get();
        const ddnNoMap = new Map();
        usersSnapshot.forEach((doc) => {
            const ddnNo = doc.get("DDN_NO");
            const wardNo = doc.get("WARD_NO");
            const ownerName = doc.get("OWNER_NAME");
            if (ddnNoMap.has(ddnNo)) {
                const existingValue = ddnNoMap.get(ddnNo);
                existingValue.count += 1;
                existingValue.docs.push({
                    id: doc.id,
                    data: { DDN_NO: ddnNo, WARD_NO: wardNo, OWNER_NAME: ownerName, ...doc.data() },
                });
            } else {
                ddnNoMap.set(ddnNo, {
                    count: 1,
                    docs: [{ id: doc.id, data: { DDN_NO: ddnNo, WARD_NO: wardNo, OWNER_NAME: ownerName, ...doc.data() } }],
                });
            }
        });
        ddnNoMap.forEach((value, ddnNo) => {
            if (value.count > 1) {
                console.log(`DDN_NO: ${ddnNo}, WARD_NO: ${value.docs[0].data.WARD_NO}, OWNER_NAME: ${value.docs[0].data.OWNER_NAME}, Size of Residents: ${value.count}`);
                console.log("Document IDs:", value.docs.map((doc) => doc.id));
                console.log("Document Data:", value.docs);
                const docIdToDelete = value.docs[1].id;
                deleteDocument(usersCollectionRef, docIdToDelete);
            }
        });
    } catch (error) {
        console.error("Error retrieving documents:", error);
    }
};

const deleteDocument = async (collectionRef, docId) => {
    try {
        await collectionRef.doc(docId).delete();
        console.log(`Document with ID ${docId} deleted successfully.`);
    } catch (error) {
        console.error(`Error deleting document with ID ${docId}:`, error);
    }
};



export const getCitizensFromUsersData = async () => {
    let dataSource = []
    await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users_meta")
        .get()
        .then((snapshot) => {
            dataSource = FilterResidentsDataFromUsers(snapshot);
        })
    return dataSource;
}

export const getCitizensCountFromUsersData = async () => {
    try {
        let count = 0;

        const snapshot = await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users_meta")
            // .where("boardedBy", "!=", "")
            .get();

        if (!snapshot.empty) {
            count = snapshot.size;
        }

        return count;
    } catch (error) {
        console.error("Error fetching user data:", error);
        return 0;
    }
}

export const getMobileUserCountFromUsersData = async () => {
    try {
        let count = 0;

        const snapshot = await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users_meta")
            .where("boardedBy", "==", "")
            .get();

        if (!snapshot.empty) {
            count = snapshot.size;
        }

        return count;
    } catch (error) {
        console.error("Error fetching user data:", error);
        return 0;
    }
}

export const getCitizensFromUsersDataDemo = async (page, phoneNumber, selectedArea, selectedStaffID, selectedDate, selectedComplete) => {
    let dataSource = [];
    let query = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users_meta")
        .orderBy("authUid");
    if (phoneNumber) {
        query = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users_meta").where("phoneNumber", "==", phoneNumber);
        const snapshot = await query.get();
        dataSource = FilterResidentsDataFromUsersDemo(snapshot);
        return dataSource;
    }
    if (selectedArea) {
        query = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users_meta").where("ward", "==", selectedArea);
        const snapshot = await query.get();
        dataSource = FilterResidentsDataFromUsersDemo(snapshot);
        return dataSource;
    }
    if (selectedStaffID) {
        query = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users_meta").where("boardedBy", "==", selectedStaffID);
        const snapshot = await query.get();
        dataSource = FilterResidentsDataFromUsersDemo(snapshot);
        return dataSource;
    }
    if (selectedDate) {
        query = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users_meta").where("date", "==", selectedDate);
        const snapshot = await query.get();
        dataSource = FilterResidentsDataFromUsersDemo(snapshot);
        return dataSource;
    }
    if (selectedComplete) {
        query = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users_meta").where("isInComplete", "==", selectedComplete);
        const snapshot = await query.get();
        dataSource = FilterResidentsDataFromUsersDemo(snapshot);
        return dataSource;
    }
    if (!(phoneNumber && selectedArea && selectedStaffID && selectedDate && selectedComplete)) {
        const pageSize = 25;
        query = query.limit(pageSize);
        if (page > 1) {
            const previousPage = await getCitizensFromUsersDataDemo(page - 1, phoneNumber, selectedArea);
            const lastVisible = previousPage[previousPage.length - 1].authUid;
            query = query.startAfter(lastVisible);
        }
    }
    const snapshot = await query.get();
    dataSource = FilterResidentsDataFromUsersDemo(snapshot);
    return dataSource;
}

export const getMobileFromUsersDataDemo = async (page, phoneNumber, selectedArea, selectedStaffID, selectedDate, selectedComplete) => {
    let dataSource = [];
    let query = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users_meta")
        .where("boardedBy", "==", "")
        .orderBy("boardedBy");
    if (phoneNumber) {
        query = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users_meta").where("phoneNumber", "==", phoneNumber);
        const snapshot = await query.get();
        dataSource = FilterMobileUserDataFromUsersDemo(snapshot);
        return dataSource;
    }
    if (selectedArea) {
        query = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users_meta").where("ward", "==", selectedArea);
        const snapshot = await query.get();
        dataSource = FilterMobileUserDataFromUsersDemo(snapshot);
        return dataSource;
    }
    if (selectedStaffID) {
        query = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users_meta").where("boardedBy", "==", selectedStaffID);
        const snapshot = await query.get();
        dataSource = FilterMobileUserDataFromUsersDemo(snapshot);
        return dataSource;
    }
    if (selectedDate) {
        query = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users_meta").where("date", "==", selectedDate);
        const snapshot = await query.get();
        dataSource = FilterMobileUserDataFromUsersDemo(snapshot);
        return dataSource;
    }
    if (selectedComplete) {
        query = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users_meta").where("isInComplete", "==", selectedComplete);
        const snapshot = await query.get();
        dataSource = FilterMobileUserDataFromUsersDemo(snapshot);
        return dataSource;
    }
    if (!(phoneNumber && selectedArea && selectedStaffID)) {
        const pageSize = 25;
        query = query.limit(pageSize);
        if (page > 1) {
            const previousPage = await getMobileFromUsersDataDemo(page - 1, phoneNumber, selectedArea);
            const lastVisible = previousPage[previousPage.length - 1].authUid;
            query = query.startAfter(lastVisible);
        }
    }
    const snapshot = await query.get();
    dataSource = FilterMobileUserDataFromUsersDemo(snapshot);
    return dataSource;
}

export const getCitizensFromUsersDataCount = async () => {
    let dataSource = [];
    let totalDocs = 0;
    let withThumbnail = 0;
    let withoutThumbnail = 0;
    let docsWithHouseImage = 0;
    let docsWithOutHouseImage = 0;

    try {
        const snapshot = await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("commercials_meta")
            .get();

        dataSource = FilterResidentsDataFromUsers(snapshot);

        // Process each document
        for (const doc of dataSource) {
            totalDocs++;

            // Check if thumbnailUrl exists
            if (doc.thumbnailUrl) {
                withThumbnail++;
            } else {
                withoutThumbnail++;
            }

            // If thumbnailUrl is not present, check for houseImage
            if (doc.houseImage) {
                docsWithHouseImage++;
            } else {
                docsWithOutHouseImage++;
            }

        }
    } catch (error) {
        console.error('Error fetching and processing data:', error);
        throw error;
    }

    console.log("Total number of documents:", totalDocs);
    console.log("Number of documents with thumbnailUrl:", withThumbnail);
    console.log("Number of documents without thumbnailUrl:", withoutThumbnail);
    console.log("Number of documents with houseImage:", docsWithHouseImage);
    console.log("Number of documents without houseImage:", docsWithOutHouseImage);

    return dataSource;
};
let count = 0;

export async function compressAndUploadImage(imageUrl, authID) {

    console.log("authID: ", authID, imageUrl)
    count++;
    console.log("count: ", count)
    let returnedUrl = "";
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("apy-token", "APY0Bv3kyJRXa5fnNbBxTzGSTHFxFoWpveEMQbRlqrYiA3zlpw5fOgCK6awfstx9L1nakOLqhsY17T");

    let raw = JSON.stringify({
        "url": imageUrl
    });

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const storageRef = firebase.storage().ref();
    const setSaathiThumbnailref = db.collection("mun").doc(window.localStorage.mun).collection("commercials_meta").doc(authID);

    try {
        if (imageUrl) {
            const response = await fetch("https://api.apyhub.com/generate/image/thumbnail/url/file?url=" + encodeURIComponent(imageUrl) + "&width=100&height=100", requestOptions);

            if (response.status === 429) {
                // Too Many Requests - Implement rate limiting or retry logic here
                console.log("Too Many Requests.");
                return;
            }

            const buffer = await response.arrayBuffer();

            if (!buffer) {
                return;
            }

            const base64 = Buffer.from(new Uint8Array(buffer)).toString('base64');
            const imageRef = "commercialsMetaImage/" + authID + "/" + new Date().getTime() + '.jpg';
            const reference = storageRef.child(imageRef);
            const snapshot = await reference.putString(base64, 'base64');
            const downloadUrl = await snapshot.ref.getDownloadURL();

            await setSaathiThumbnailref.set({
                thumbnailUrl: downloadUrl
            }, { merge: true });

            console.log("downloadUrl: ", downloadUrl)

            returnedUrl = downloadUrl;
        } else {
            console.log("Image not available for ID " + authID);
        }
    } catch (error) {
        console.log('Error uploading image:', error);
    }

    return returnedUrl;

}

export const createCitizensFromUsersData = async () => {
    let dataSource = [];

    try {
        const snapshot = await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("commercials_meta")
            .get();

        dataSource = FilterResidentsDataFromUsers(snapshot);

        // Process each document
        for (const doc of dataSource) {
            // Check if thumbnailUrl exists
            if (!doc.thumbnailUrl) {
                // If thumbnailUrl is not present, check for houseImage
                if (doc.commercialImage) {
                    // Download, compress, and upload the image
                    // const compressedImageUrl = await compressAndUploadImage(doc.houseImage, doc.authUid);
                    await compressAndUploadImage(doc.commercialImage, doc.authUid);

                    // // Update the Firestore document with the compressed image URL
                    // await db
                    //     .collection("mun")
                    //     .doc(window.localStorage.mun)
                    //     .collection("users_meta")
                    //     .doc(doc.authUid) // Assuming each document has an 'id' field
                    //     .update({
                    //         thumbnailUrl: compressedImageUrl,
                    //     });
                }
            }
        }
    } catch (error) {
        console.error('Error fetching and processing data:', error);
        throw error;
    }
    console.log("dataSource: ", dataSource)
    return dataSource;
};

export const storeDataOfResidents = (data) => {
    // console.log("data",data)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users_meta")
        .doc(data.authUid)
        .set(data)
}

export const storeDataOCommercials = (data) => {
    console.log("data comm", data)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("commercials_meta")
        .doc(data.authUid)
        .set(data)
}

export const fetchResidents = async (ward) => {
    let dataSource = [];
    if (!ward) {
        await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users")
            .get()
            .then((snapshot) => {
                dataSource = ResidentsData(snapshot);

            })
    } else if (ward) {
        await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users")
            .where("ward", "==", ward)
            .get()
            .then((snapshot) => {
                dataSource = ResidentsData(snapshot);
            })
    }
    return dataSource;
}

export const fetchWardResidents = async (ward) => {
    let dataSource = [];
    if (ward) {
        await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users")
            .where("ward", "==", ward)
            .get()
            .then((snapshot) => {
                dataSource = ResidentsData(snapshot);
            })
    }
    return dataSource;
}

export function updateResident(id, data) {
    if (window.localStorage.mun == 'buxar' || window.localStorage.mun == 'adityapur' || window.localStorage.mun == "jagdalpur") {
        let key = HASH_KEY;

        data.name = CryptoJS.AES.encrypt(data.name || '', key).toString();
        data.address = CryptoJS.AES.encrypt(data.address || '', key).toString();
        let latitude = data.lat != undefined ? data.lat.toString() : "0";
        data.lat = CryptoJS.AES.encrypt(latitude, key).toString();
        let longitude = data.long != undefined ? data.long.toString() : "0";
        data.long = CryptoJS.AES.encrypt(longitude, key).toString();
    }
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users")
        .doc(id)
        .set(data, { merge: true })
}
export function getResidents(ward) {
    // console.log("ward", ward, typeof ward);
    if (!ward) {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users")
            .get();
    }
    if (ward) {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("users")
            .where("ward", "==", ward)
            .get();
    }
}


export function getDashboardResidents() {

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users")
        .get();

}


export function residentLocation(reports, lat, long) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users")
        .doc(reports.id)
        .update({ lat: lat, long: long })
}

export function addKarmi(id, data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmi")
        .doc(id)
        .set(data);
}

export function isKarmiExists(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmi")
        .doc(id)
        .get();
}

export function getKarmi() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmi")
        .get();
}

export function updateKarmi(id, updateObj) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmi")
        .doc(id)
        .update(updateObj);
}

export function getTaskManager() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("manager")
        .get();
}

export function getComplaintManager() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaintManager")
        .get()
}

export function karmiSetting(data) {

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("manager")
        .doc(data.id)
        .set(data);

}

export function ManagerSetting(data) {
    // debugger
    if (data.isSaathi === true) {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("manager")
            .doc(data.doc_id)
            .set(
                {
                    requestType: data.requestType,
                    saathiDoc_id: data.doc_id,
                    saathi: data.name,
                    ward: data.ward,
                    type: 'saathi'
                },
                { merge: true }
            );
    }
    if (data.isKarmi === true) {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("manager")
            .doc(data.doc_id)
            .set(
                {
                    requestType: data.requestType,
                    karmiDoc_id: data.doc_id,
                    karmi: data.name,
                    ward: data.ward,
                    type: 'karmi'
                },
                { merge: true }
            );
    }
}

export function ComplaintManagerSetting(data) {
    // debugger
    if (data.isSaathi === true) {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("complaintManager")
            .doc(data.doc_id)
            .set(
                {
                    requestType: data.requestType,
                    saathiDoc_id: data.doc_id,
                    saathi: data.name,
                    ward: data.ward,
                    type: 'saathi'
                },
                { merge: true }
            );
    }
    if (data.isKarmi === true) {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("complaintManager")
            .doc(data.doc_id)
            .set(
                {
                    requestType: data.requestType,
                    karmiDoc_id: data.doc_id,
                    karmi: data.name,
                    ward: data.ward,
                    type: 'karmi'
                },
                { merge: true }
            );
    }
}

export function fetchKarmi() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmi")
        .where("isApproved", "==", true)
        .get();
}

export function deleteKarmi(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmi")
        .doc(id)
        .delete()
}

export function getKarmiTasks(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmi")
        .doc(id)
        .collection("tasks")
        .get();
}

export function getKarmiComplaints(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmi")
        .doc(id)
        .collection("complaints")
        .get();
}

export function getKarmiManager() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("manager")
        .get()
}

export function getSaathiTasks(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(id)
        .collection("tasks")
        .get();
}

export function getSaathiComplaints(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(id)
        .collection("complaints")
        .get();
}

export function getSaathiAssignedTasks(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        .where("assignee", "==", id)
        .get();
}

export function getSaathiAssignedComplaints(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
        .where("assignee", "==", id)
        .get();
}



export function getDashboardSaathi(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("attendance")
        .doc(date)
        .collection("saathi")
        .where("status", "==", true)
        .get();
}

export function getDashboardLocationSaathi(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathiGeo")
        .doc(date)
        .collection("saathi")
        .get();
}

export function getDashboardLocationSaathiDash(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathiGeo")
        .doc(date)
        .collection("saathi")
    //.get();
}

export function getDashboardKarmi(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("attendance")
        .doc(date)
        .collection("Karmi")
        .where("status", "==", true)
        .get();
}

export function getDashboardLocationKarmi(date) {

    // debugger
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmiGeo")
        .doc(date)
        .collection("karmi")
        .get();
}

export function addSurvey(data) {

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("survey")
        .doc()
        .set(data);
}

export function addOnboardSurvey(data) {
    // console.log(data)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("onboard")
        .doc("onboard")
        .set(data);
}

export function fetchSurvey() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("survey")
        .get();
}

export function getSurvey() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("survey")
        .get();
}

export function saveEditSurvey(id, saathi) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("survey")
        .doc(id)
        .set({ saathi: saathi }, { merge: true })
}

export function deleteSelectedSurvey(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("survey")
        .doc(id)
        .delete();
}

export function getSelectedSurvey(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("survey")
        .doc(id)
        .get();
}

export function saveSelectedEditSurvey(id, data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("survey")
        .doc(id)
        .set(data)
}

export function addSurveyForm(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("surveyForm")
        .doc()
        .set(data);
}

//    export function getKarmiAttendance(date,phoneNumber){

//     return db
//          .collection('mun')
//          .doc(window.localStorage.mun)
//          .collection('attendance')
//          .doc(date)
//          .collection('attendance')
//          .where('phoneNumber', '==', phoneNumber)
//          .get()
//  }
export function getKarmiAttendance(date, phoneNumber) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("attendance")
        .doc(date)
        .collection("karmi")
        .where("phoneNumber", "==", phoneNumber)
        .where("status", "==", true)
        .get();
}


export async function getMonthlySaathiAttendance(date, id) {
    try {
        const snapshot = await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("saathiGeo")
            .doc(date)
            .collection("attendance")
            .doc(id)
            .get();
        return snapshot;
    } catch (error) {
        console.error("Error fetching monthly saathi attendance:", error);
        return null;
    }
}


// export function getSaathiAttendance(date) {
//   return db
//     .collection("mun")
//     .doc(window.localStorage.mun)
//     .collection("attendance")
//     .doc(date)
//     .collection("saathi")
//     .where("status", "==", true)
//     .get();
// }
export const getTodaySaathiData = async (date) => {
    let temp = [];
    try {
        const [staff_attendance, staff_location] = await Promise.all([
            getSaathiAttendance(date),
            fetchSaathiLocation(date)
        ])
        staff_attendance?.docs?.map((each_att) => {
            let isDocExist = staff_location?.docs?.find((each_loc) => each_loc.id == each_att.id);
            if (isDocExist) {
                let combined = { ...each_att.data(), ...isDocExist.data(), ...{ "authUid": isDocExist.id } }
                temp.push(combined);
            }
        })
        return temp;
    } catch (e) {
        return temp;
    }

}

export function getSaathiPendingAttendance(date) {
    const collectionName = "pendingAttendance"; // Use the correct collection name here
    // const collectionName = "pendingAttendace"; // Use the correct collection name here
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathiGeo")
        .doc(date)
        .collection(collectionName)
        .get();
}

export function deleteSaathiPendingAttendance(date, saathiid, type) {
    const collectionName = "pendingAttendance"; // Use the correct collection name here
    // const collectionName = "pendingAttendace"; // Use the correct collection name here
    // console.log("type: ", type)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathiGeo")
        .doc(date)
        .collection(collectionName)
        .get()
        .then((querySnapshot) => {
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    const authUid = data.authUid;
                    const attendanceType = data.attendanceType
                    if (authUid === saathiid && attendanceType === type) {
                        doc.ref.delete().then(() => {
                            console.log("Matching Data has been deleted.");
                        }).catch((error) => {
                            console.error("Error deleting matching data:", error);
                            alert("Error deleting pending attendance data:", error);
                        });
                    } else {
                        console.log("Matching Data for saathiid:", saathiid, "not found.");
                    }
                });
            } else {
                console.log("No pending attendance data found for the specified date and ID.");
            }
        })
        .catch((error) => {
            console.error("Error deleting pending attendance data:", error);

        });
}

export function rejectSaathiPendingAttendance(date, saathiid, type, name) {
    const collectionName = "pendingAttendance"; // Use the correct collection name here

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathiGeo")
        .doc(date)
        .collection(collectionName)
        .get()
        .then((querySnapshot) => {
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    const authUid = data.authUid;
                    const attendanceType = data.attendanceType;
                    let typeDisplay = "";
                    if (attendanceType === "inTime") {
                        typeDisplay = "In Time"
                    } else if (attendanceType === "outTime") {
                        typeDisplay = "Out Time"
                    }

                    if (authUid === saathiid && attendanceType === type) {
                        doc.ref.update({ isReject: true }).then(() => {
                            console.log("Matching Data has been rejected.");
                            alert(`${typeDisplay} Attendance for ${name} has been rejected.`);
                            return;
                        }).catch((error) => {
                            console.error("Error updating data with rejection:", error);
                            alert("Error rejecting pending attendance data:", error);
                        });
                    } else {
                        console.log("Matching Data for saathiid:", saathiid, "not found.");
                    }
                });
            } else {
                console.log("No pending attendance data found for the specified date and ID.");
            }
        })
        .catch((error) => {
            console.error("Error rejecting pending attendance data:", error);
        });
}




export async function addPendingSaathiAttendance(date, id, attendanceData, time, type, name) {
    const docRef = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathiGeo")
        .doc(date)
        .collection("attendance")
        .doc(id);

    const doc = await docRef.get();
    let statusArray = [];

    if (type === "inTime") {
        if (doc.exists) {
            const existingData = doc.data();
            if (Array.isArray(existingData.status) && existingData.status.length > 0) {
                const lastStatus = existingData.status[existingData.status.length - 1];
                if (lastStatus.hasOwnProperty("status") && lastStatus.status === false) {
                    // console.error(`In Time Attendance for ${name} already exists.`);
                    alert(`Marking In Time Attendance for ${name}.`);
                    existingData.status.push({ status: true, timestamp: time });
                    await docRef.update({ status: existingData.status });
                }
                else {
                    alert(`In Time Attendance for ${name} is already marked.`);
                }
            } else {
                // console.log(`Marking In Time Attendance for ${name}.`);
                alert(`Marking First In Time Attendance for ${name}.`);
                await docRef.set({ status: statusArray, ...attendanceData });
            }

        } else {
            // console.log(`Marking In Time Attendance for ${name}.`);
            alert(`Marking In Time Attendance for ${name}.`);
            await docRef.set({ status: statusArray, ...attendanceData });
        }
    } else if (type === "outTime") {
        if (!doc.exists) {
            // console.error(`In Time Attendance for ${name} is not marked yet.`);
            alert(`In Time Attendance for ${name} is not marked yet.`);

        } else {
            const existingData = doc.data();
            if (Array.isArray(existingData.status) && existingData.status.length > 0) {
                const lastStatus = existingData.status[existingData.status.length - 1];
                if (lastStatus.hasOwnProperty("status") && lastStatus.status === true) {
                    //console.log(`In Time Attendance for ${name} exists.`);
                    existingData.status.push({ status: false, timestamp: time });
                    //console.log(`Marking Out Time Attendance for ${name}.`);
                    alert(`Marking Out Time Attendance for ${name}.`);
                    await docRef.update({ status: existingData.status });
                } else {
                    // console.error(`Out Time Attendance for ${name} is already marked.`);
                    alert(`Out Time Attendance for ${name} is already marked.`);
                }
            } else {
                console.log(existingData.status ? `The "status" array is empty.` : `The record for ${name} does not have a "status" array.`);
            }
        }
    }
}





export function getSaathiAttendance(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathiGeo")
        .doc(date)
        .collection("attendance")
        // .where("status", "==", true)
        .get();
}

export function getKarmiAttendance1(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmiGeo")
        .doc(date)
        .collection("attendance")
        // .where("status", "==", true)
        .get();
}

export function getReportsKarmiAttendance(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("attendance")
        .doc(date)
        .collection("attendance")
        .where("status", "==", true)
        .get();
}

export const getKarmiDate = async () => {
    var arr2 = [];
    let arr = await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("attendance")
        .get();
    for (let i = 0; i < arr.docs.length; i++) {
        arr2.push(arr.docs[i].id);
    }

    return arr2;
};

export function addgeofence(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("wards")
        .doc()
        .set(data);
}

export function getgeofence() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("geofences")
        .get();
}

export function getgeofenceData(ward) {
    // debugger
    // console.log('w',ward)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("wards")
        // .where("id", "array-contains-any", [ward])
        .get();
}

export function getWardGeofence(ward) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("wards")
        .where("name", "==", ward)
        .get();
}

export function getGeofenceVehicles() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("geofence_vehicles")
        .where("ack", "==", false)
        .orderBy('time', 'desc')
        .get()
}

export function getGeofenceVehiclesDaterange(date) {
    // console.log('date is', date);
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("geofence_vehicles")
        .where("date", "==", date)
        .orderBy('time', 'desc')
        .get()
}

export function geofenceNotificationAck(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("geofence_vehicles")
        .doc(id)
        .set({ ack: true }, { merge: true })
}

export function addTask(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        .doc()
        // .set(data);
        .set(
            {
                task_des: data.task_des,
                created_date: data.created_date,
                createdTime: data.CreatedTime,
                name: data.name,
                message: data.message,
                phoneNumber: data.phoneNumber,
                address: data.address,
                landmark: data.landmark,
                email: data.email,
                ward: data.ward,
                etc: data.etc,
                priority: data.priority,
                selectedWasteType: {
                    id: data.task_type,
                    name: data.task_type,
                },
                state: data.state,


            },
            { merge: true }
        );
}


export function isTaskExists(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        .doc(id)
        .get();
}

export function getTask() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        .orderBy("t_id", "desc")
        .get();
}

export function getDeshboardTask() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        .orderBy("t_id", "desc")
    // .get();
}

export function getTaskList() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        // .orderBy("t_id", "desc")
        .get();
}


export function updateTask(id, updateObj) {

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        .doc(id)
        .update(updateObj);
}

export function assignComment(data) {

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        .doc(data.id)
        .update({
            COMMENTS: data.COMMENTS

        });
}

export function deleteTask(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        .where("id", "==", id)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                doc.ref.delete();
            });
        });
}

export function addTaskGeotag(id, location) {
    const taskRef = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        .doc(id)
        .set({ location }, { merge: true });
}

export function addComplaintGeotag(id, location) {
    const compRef = db.collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
        .get(id)
        .set({ location: location }, { merge: true })
}

export function getAllComplaints() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
        .get();
}

export function fetchActiveTasks() {
    return (
        db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("tasks")
            .where("state", "==", "ACTIVE")
            // .orderBy('createdTime', 'desc')
            // .where("createdTime", ">=", start)
            .get()
    );
}

export function fetchAssignedTasks() {
    return (
        db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("tasks")
            .where("state", "==", "ASSIGNED")
            // .orderBy('createdTime', 'desc')
            // .where("createdTime", ">=", start)
            .get()
    );
}

export function fetchPendingTasks() {
    return (
        db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("tasks")
            .where("state", "==", "PENDING")
            .get()
    );
}

export function fetchClosedTasks() {
    return (
        db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("tasks")
            .where("state", "==", "CLOSED")
            .get()
    );
}

export function getMulticast(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("multicast")
        .where('date', '==', date)
        .get();
}

export async function addVehicle(data) {
    console.log("Data for adding vehicle: ", data);
    try {
        await db.runTransaction(async (transaction) => {
            const vehRef = db
                .collection("mun")
                .doc(window.localStorage.mun)
                .collection("vehicles")
                .doc();

            transaction.set(vehRef, data);

            if (data.device_id && data.device_id.trim() !== "") {
                const deviceRef = db
                    .collection("mun")
                    .doc(window.localStorage.mun)
                    .collection("devices")
                    .doc(data.device_id.trim());

                transaction.update(deviceRef, {
                    vehicle_num: data.vehicle_id,
                    vehicle_name: data.vehicle_name,
                    phone_num: data.phone_num,
                    ward_id: data.ward_id,
                    vehicle_type: data.vehicle_type,
                });
            }
        });

        console.log("Transaction successful adding vehicle!");
        return true;
    } catch (error) {
        console.error("Transaction failure:", error, error.message);
        alert("Error Adding Vehicle.");
        return false;
    }
}


export function addComplaint(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
        .doc()
        .set(data);
}

export async function updateVehicle(id, updateObj) {
    try {
        await db.runTransaction(async (t) => {
            let vehRef = db
                .collection("mun")
                .doc(window.localStorage.mun)
                .collection("vehicles")
                .doc(id);

            t.update(vehRef, updateObj);

            if (updateObj.device_id && updateObj.device_id.trim() !== "") {
                let deviceRef = db
                    .collection("mun")
                    .doc(window.localStorage.mun)
                    .collection("devices")
                    .doc(updateObj.device_id.trim());

                t.update(deviceRef, {
                    vehicle_num: updateObj.vehicle_id,
                    vehicle_name: updateObj.vehicle_name,
                    phone_num: updateObj.phone_num,
                    ward_id: updateObj.ward_id,
                    vehicle_type: updateObj.vehicle_type,
                });
            }
        });
        console.log("Transaction successful editing vehicle!");
        return true;
    } catch (error) {
        console.error("Transaction failure editing vehicle:", error, error.message);
        alert("Error Editing Vehicle.");
        return false;
    }
}


export function deleteVehicle(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicles")
        .where("vehicle_id", "==", id)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                doc.ref.delete();
            });
        });
}

export function isVehicleExists(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicles")
        .doc(id)
        .get();
}

export const getReportCheckpoints = async () => {
    let arr2 = Array();
    let arr = await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("report_checkpoints")
        .get();
    for (let i = 0; i < arr.docs.length; i++) {
        let value = arr.docs[i].data();
        for (let key in value) {
            for (let key1 in value[key]) {
                arr2.push(value[key][key1]);
            }
        }
    }
    return arr2;
};


export const getReportCheckgates = async (date_formatted) => {
    try {
        const docRef = db.collection(`mun/${window.localStorage.mun}/report_checkgates`).doc(date_formatted);
        const doc = await docRef.get();

        if (doc.exists) {
            return doc.data().checkgate_doc_data || {};
        } else {
            console.error("Document not found in report_checkgates.");
            return null;
        }
    } catch (error) {
        console.error("Error loading document in report_checkgates:", error);
        return null;
    }
};



export async function getTodayReportCheckpoints(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("report_checkpoints")
        .doc(id)
        .get();
}

export function getTodayReportCheckpointsDashboard(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("report_checkpoints")
        .doc(id)
}


export function getTripsData(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicleTrips_report")
        .doc(date)
        .get();
}

export function getTrips() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicleTrips")
        .get();
}

export const getGeoHashCheckpoints = () => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("geohashCheckpoints")
        .get();
};

export const getGeoHashCheckpointOnId = (id) => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("geohashCheckpoints")
        .doc(id)
        .get();
};

export function getTrackcar(dateString) {
    return db.collection("trackcar").doc(dateString).get();
}

export function getVehicleNotification(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicles_notifications")
        .where("date", "==", date)
        .get();
}

export function addVehicleRemark(id, remark) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicles_notifications")
        .doc(id)
        .set({
            remark: remark,
            acknowledge: true,
        }, { merge: true })

}


export function addOfflineVehicleRemark(id, remark) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicles")
        .doc(id)
        .set({
            remark: remark,
            acknowledge: true,
        }, { merge: true })
}

export function fetchVehicles(tag_id) {
    if (tag_id != undefined && tag_id.length > 0 && tag_id) {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("vehicles")
            .where("tags_id", "in", tag_id)
            .orderBy("vehicle_id", "asc")
            .get();
    }
}

export function fetchVehiclesUser(ward) {
    // debugger
    // console.log("ward", ward);

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicles")
        .where("ward_id", "array-contains-any", [ward])
        .orderBy("vehicle_id", "asc")
        .get();

}

export const updateVehicleRoute = async (id, data) => {

    await db.collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicles")
        .doc(id)
        .set({ route_group: data.route }, { merge: true });
    await db.collection("mun")
        .doc(window.localStorage.mun)
        .collection("devices")
        .doc(data.device_id.trim())
        .set({ route_group: data.route }, { merge: true });

}

export const DeleteEachRoute = (id) => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("routes")
        .doc(id)
        .delete()
}

export const DeleteEachRoutes = async (id) => {

    await db.collection("mun")
        .doc(window.localStorage.mun)
        .collection("route_group")
        .doc(id)
        .delete()
}


export async function getVehicles(tag_id) {
    let query = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicles")

    if (tag_id != undefined && tag_id.length > 0 && tag_id) {
        query = query.where("tags_id", "in", tag_id);
    }

    query.orderBy("vehicle_id", "asc");

    return query.where("device_id", "!=", "")
        .orderBy("device_id", "asc").get();
}

// export async function getVehicles(tag_id) {
//     let query = db
//         .collection("mun")
//         .doc(window.localStorage.mun)
//         .collection("vehicles")
//     if (tag_id != undefined && tag_id.length > 0 && tag_id) {
//         query = query.where("tags_id", "in", tag_id)
//     }
//     return query.orderBy("vehicle_id", "asc").get();
// }

// export async function getVehicles(tag_id, lastDocument) {
//     let query = db
//         .collection("mun")
//         .doc(window.localStorage.mun)
//         .collection("vehicles");

//     if (tag_id != undefined && tag_id.length > 0 && tag_id) {
//         query = query.where("tags_id", "in", tag_id);
//     }

//     query = query.orderBy("vehicle_id", "asc");

//     if (lastDocument) {
//         query = query.startAfter(lastDocument);
//     }

//     return query.limit(10).get(); // Adjust the batch size as needed
// }

export function fetchWards() {
    return (
        db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("wards")
            .get()
    );
}

export function fetchTags() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tags")
        .get();
}
export function fetchRfidTags() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("rfidTags")
        .get();
}
export function fetchRfidDevice() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("rfidDevices")
        .get();
}
export function fetchSuggestions() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("suggestion")
        .get();
}

export function fetchFeedbacks() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("feedback")
        .get();
}


export function saveFeedbacks(feedbacks) {
    const feedbackCollection = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("feedback");

    feedbacks.forEach(feedback => {
        let docId = uuidv4().replace(/-/g, '').substring(0, 20); // Generate a random document ID and trim to 20 characters

        // Convert createdTime to Firestore Timestamp
        const createdTime = firebase.firestore.Timestamp.fromMillis(feedback.createdTime);

        // Sanitize the feedback object to replace undefined values with null
        const sanitizedFeedback = JSON.parse(JSON.stringify(feedback, (key, value) => {
            return value === undefined ? null : value;
        }));

        // Add createdTime to the sanitized feedback object
        sanitizedFeedback.createdTime = createdTime;

        feedbackCollection.doc(docId).set(sanitizedFeedback, { merge: true })
            .catch(error => {
                console.error("Error writing document: ", error);
            });
    });
}

export function isComplaintExists(id) {
    return db.collection("mun").doc("ara").collection("complaints").doc(id).get();
}

export function getTasks(data, startDate, endDate) {

    if (data == "ALL") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("tasks")
            .orderBy("created_date", "asc")
            .where("created_date", ">=", startDate)
            .where("created_date", "<=", endDate)
            .get();
    } else {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("tasks")
            .orderBy("created_date", "asc")
            .where("state", "==", data)
            .where("created_date", ">=", startDate)
            .where("created_date", "<=", endDate)
            .get();
    }
}

export function getComplaintsDashboard() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
        .get()

}

export function getVehiclesMeta() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicles_meta")
        .doc("vehiclesMeta")
        .get()
}



export const storeVehicleMetaData = (obj) => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("vehicles_meta")
        .doc("vehiclesMeta")
        .set({ data: obj });
}

export const storeComplaintsMetaData = (obj) => {
    console.log("storeComplaintsMetaData: ", obj)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints_meta")
        .doc("complaintsMeta")
        .set({ data: obj });
}

export function getComplaintsMeta() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints_meta")
        .doc("complaintsMeta")
        .get()
}

export const storeHouseholdMetaData = (obj) => {
    console.log("obj house: ", obj)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("household_meta")
        .doc("householdMeta")
        .set({ data: obj });
}

export function getHouseholdMeta() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("household_meta")
        .doc("householdMeta")
        .get()
}

export const storeTasksMetaData = (obj) => {
    console.log("storeTasksMetaData: ", obj)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks_meta")
        .doc("tasksMeta")
        .set({ data: obj });
}

export function getTasksMeta() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks_meta")
        .doc("tasksMeta")
        .get()
}

export const storeFeedbacksMetaData = (obj) => {
    console.log("storeFeedbacksMetaData: ", obj)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("feedbacks_meta")
        .doc("feedbacksMeta")
        .set({ data: obj });
}

export function getFeedbacksMeta() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("feedbacks_meta")
        .doc("feedbacksMeta")
        .get()
}

export function getDashboardComplaintsDash() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
    // .get()

}


export function getComplaints(data, startDate, endDate) {

    //  console.log(data,startDate,endDate)
    if (data == "ALL") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("complaints")
            .orderBy("created_date", "asc")
            .where("created_date", ">=", startDate)
            .where("created_date", "<=", endDate)
            .get()

    } else {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("complaints")
            .orderBy("created_date", "asc")
            .where("state", "==", data)
            .where("created_date", ">=", startDate)
            .where("created_date", "<=", endDate)
            .get();
    }
}

export function getShowComplaints(type) {
    if (type == "ALL") {
        return (
            db
                .collection("mun")
                .doc(window.localStorage.mun)
                .collection("complaints")
                .get()
        );
    } else {
        return (
            db
                .collection("mun")
                .doc(window.localStorage.mun)
                .collection("complaints")
                .where("state", "==", type)
                .get()
        );
    }
}
const getSpotFineRef = () => {
    return db.collection("mun").doc(window.localStorage.mun).collection("spot_fine")
}

export function getAllSpotFines(type) {
    if (type == "ALL") {
        return getSpotFineRef().get()
    } else {
        return getSpotFineRef().where("state", "==", type).get()
    }
}

export function assignSpotfineComments(data) {

    return getSpotFineRef()
        .doc(data.id)
        .update({
            COMMENTS: data.COMMENTS
        });
}

export const getAssignedSpotfineComments = (complaintId) => {
    return getSpotFineRef()
        .doc(complaintId)
        .get()

}

export function assignSpotfine(data) {
    return getSpotFineRef()
        .doc(data.id)
        .update({
            state: data.state,
            assignee: data.assignee,
            assigneeName: data.assigneeName,
            assigneePhoneNumber: data.assigneePhoneNumber,
            comment: data.comment,
            isSaathi: data.isSaathi,
            isKarmi: false,
            time_frame: data.time_frame
        });
}

export function PendingSpotfine(data) {
    return getSpotFineRef()
        .doc(data.id)
        .update({
            state: data.state,
            comment: data.comment,
        });
}

export function CloseSpotfine(data) {
    return getSpotFineRef()
        .doc(data.id)
        .set({
            workDoneImage:
            {
                state: data.status,
                comment: data.comment,
                imageUrl: data.pictures,
                date: data.date,
                lat: data.lat,
                long: data.long
            }, state: data.status
        }, { merge: true });
}
export function getShowTasks(type) {
    if (type == "ALL") {
        return (
            db
                .collection("mun")
                .doc(window.localStorage.mun)
                .collection("tasks")
                .get()
        );
    } else {
        return (
            db
                .collection("mun")
                .doc(window.localStorage.mun)
                .collection("tasks")
                .where("state", "==", type)
                .get()
        );
    }
}

export function assignComplaint(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
        .doc(data.id)
        .update({
            state: data.state,
            assignee: data.assignee,
            assigneeName: data.assigneeName,
            assigneePhoneNumber: data.assigneePhoneNumber,
            comment: data.comment,
            isSaathi: data.isSaathi,
            isKarmi: data.isKarmi,
            // karmi_doc_id: data.karmi_doc_id,
            // karmi_name: data.karmi_name,
            time_frame: data.time_frame
        });
}

export function assignTask(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        .doc(data.id)
        .update({
            state: data.state,
            assignee: data.assignee,
            assigneeName: data.assigneeName,
            assigneePhoneNumber: data.assigneePhoneNumber,
            comment: data.comment,
            isSaathi: data.isSaathi,
            isKarmi: data.isKarmi,
            time_frame: data.time_frame
        });
}

export function fetchAssignedComplaints() {
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    return (
        db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("complaints")
            .where("state", "==", "ASSIGNED")
            .orderBy("createdTime", "desc")
            .get()
    );
}

export function fetchClosedComplaints() {
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    return (
        db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("complaints")
            .where("state", "==", "CLOSED")
            .orderBy("createdTime", "desc")
            .get()
    );
}

export function fetchPendingComplaints() {
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);
    return (
        db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("complaints")
            .where("state", "==", "PENDING")
            .orderBy("createdTime", "desc")
            .get()
    );
}

export function assignComments(data) {

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
        .doc(data.id)
        .update({
            COMMENTS: data.COMMENTS
        });
}

export const getAssignedComments = (complaintId) => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
        .doc(complaintId)
        .get()

}

export function closeComplaint(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
        .doc(data.id)
        .set({
            workDoneImage:
            {
                state: data.status,
                comment: data.comment,
                imageUrl: data.pictures,
                date: data.date,
                lat: data.lat,
                long: data.long
            }, state: data.status
        }, { merge: true });
}

export function closeTask(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        .doc(data.id)
        .set({
            workDoneImage:
            {
                state: data.status,
                comment: data.comment,
                imageUrl: data.pictures,
                date: data.date,
                lat: data.lat,
                long: data.long
            }, state: data.status
        }, { merge: true });
}

export function activateComplaint(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
        .doc(data.id)
        .update({
            state: data.state,
        });
}

export function pendingComplaint(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("complaints")
        .doc(data.id)
        .update({
            state: data.state,
            comment: data.comment,
        });
}

export function pendingTask(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("tasks")
        .doc(data.id)
        .update({
            state: data.state,
            comment: data.comment,
        });
}

/*export function getAcknowledge(date) {
  console.log('date api', date)
  return db
    .collection("mun")
    .doc(window.localStorage.mun)
    .collection("acknowledge")
    .doc(date)
    .collection("acknowledge")
    .get();
} */

export function getAcknowledge(date) {
    // console.log("date api", date)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("acknowledge")
        .doc(date)
        .collection("acknowledge")
        .get()
}

export function getAcknowledgedCommercial(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("acknowledge")
        .doc(date)
        .collection("commercialAck")
        .get()
}

export function getWardWiseAckCommercial(date, ward) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("acknowledge")
        .doc(date)
        .collection("commercialAck")
        .where("ward_id", "==", ward)
        .get()
}

export function getVehicleAttendedHouseholds(date) {
    // console.log("test,..", date)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("acknowledge")
        .doc(date)
        .collection("vehicle_acknowledge")
        .get()
}

export function getVehicleAttendedCommercial(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("acknowledge")
        .doc(date)
        .collection("vehicle_commercialAck")
        .get()
}

export function getWardWiseVehicleAttCommercial(date, ward) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("acknowledge")
        .doc(date)
        .collection("vehicle_commercialAck")
        .where("ward_id", "==", ward)
        .get()
}

export function fetchCtptsAttendance(date) {
    // console.log("ctpt date",date)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("ctpt_attendance")
        .doc(date)
        .collection('ctpt_attendance')
        .get()
}

export function updateDriverStatus(phone_num, updateObj) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("drivers")
        .where("phone_num", "==", phone_num)
        .update(updateObj);
}

export function getMuncipalities() {
    const dbRef = firebase.database().ref("munVsWards");
    return dbRef.once("value");
}

export function getAreas() {
    const dbRef = firebase.database().ref("areas");
    return dbRef.once("value");
}

export function getTrucks() {
    const dbRef = firebase.database().ref("trucks");
    return dbRef.once("value");
}

export function getTruckById(id) {
    const dbRef = firebase.database().ref(`areaCodeVsDriver/${id}/`);
    return dbRef.once("value");
}

export function getTruckByMuncipality(muncipality) {
    const dbRef = firebase.database().ref(`munVsDrivers/${muncipality}`);
    return dbRef.once("value");
}

export function getDriverData(muncipalityId) {
    const dbRef = firebase.database().ref(`munVsDrivers/${muncipalityId}`);
    return dbRef.once("value");
}

export function getSathisData(muncipalityId) {
    const dbRef = firebase.database().ref(`munVsAgent/${muncipalityId}`); //need to change the node name here
    return dbRef.once("value");
}

export function updateSathiStatus(muncipalityId, agentId, status) {
    const dbRef = firebase.database().ref();
    const updates = {};
    updates[`munVsAgent/${muncipalityId}/${agentId}/approved`] = !status;
    return dbRef.update(updates);
}

export function getSaathi() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        // .where("isApproved", "==", true)
        .get();
}

export function copyThumbnailToImage(authUid) {
    const saathiRef = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(authUid);

    return saathiRef.get()
        .then((doc) => {
            if (doc.exists) {
                const docData = doc.data();
                if (docData.thumbnailUrl && !docData.imageUrl) {
                    const updatedData = {
                        ...docData,
                        imageUrl: docData.thumbnailUrl
                    };
                    updateStaffMeta(authUid, docData.thumbnailUrl, docData.thumbnailUrl)
                    return saathiRef.update(updatedData)
                        .then(() => {
                            console.log("Thumbnail copied to imageUrl and updated in the database");
                        })
                        .catch((error) => {
                            console.error("Error updating document:", error);
                        });
                } else {
                    console.log("ThumbnailUrl is empty or imageUrl is already populated.");
                }
            } else {
                console.log("Document not found");
            }
        })
        .catch((error) => {
            console.error("Error getting document:", error);
        });
}

export function copyImageUrlToThumbnailUrl(authUid) {
    console.log("copyImageUrlToThumbnailUrl: ", authUid)
    const saathiRef = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(authUid);

    return saathiRef.get()
        .then((doc) => {
            console.log("document exist: ", doc.exists)
            if (doc.exists) {
                const docData = doc.data();
                if (docData.imageUrl) {
                    const updatedData = {
                        ...docData,
                        thumbnailUrl: docData.imageUrl
                    };
                    console.log("updatedData", updatedData)
                    updateStaffMeta(authUid, docData.imageUrl, docData.imageUrl)
                    return saathiRef.update(updatedData)
                        .then(() => {
                            console.log("Image URL copied to Thumbnail URL and updated in the database.");
                            console.log("Updated URLs:", { imageUrl: docData.imageUrl, thumbnailUrl: docData.imageUrl });
                        })
                        .catch((error) => {
                            console.error("Error updating document:", error);
                        });
                } else {
                    console.log("Image URL is empty or Thumbnail is already populated.");
                }
            } else {
                console.log("Document not found");
            }
        })
        .catch((error) => {
            console.error("Error getting document:", error);
        });
}

export function fetchSaathiAttendance(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathiGeo")
        .doc(date)
        .collection("attendance")
        .get()
}

export function getTotalPresentAbsent() {
    const saathiPromise = getSaathi().then((saathiSnapshot) => {
        const saathiData = saathiSnapshot.docs.map((doc) => doc.data());
        return saathiData;
    });
    const today = new Date().toISOString().split('T')[0];
    const saathiAttendancePromise = fetchSaathiAttendance(today).then((attendanceSnapshot) => {
        const attendanceData = attendanceSnapshot.docs.map((doc) => ({
            id: doc.id
        }));
        return attendanceData;
    });
    return Promise.all([saathiPromise, saathiAttendancePromise]).then(([saathiData, attendanceData]) => {
        const wardData = {};
        if (saathiData && Array.isArray(saathiData)) {
            saathiData.forEach((saathi) => {
                if (saathi.ward && Array.isArray(saathi.ward)) {
                    saathi.ward.forEach((ward) => {
                        if (!wardData[ward]) {
                            wardData[ward] = { ward, total: 0, present: 0, absent: 0 };
                        }
                        wardData[ward].total += 1;
                    });
                }
            });
        }
        if (attendanceData && Array.isArray(attendanceData)) {
            attendanceData.forEach((attendance) => {
                const saathiMatch = saathiData.find((saathi) => saathi.authUid === attendance.id);
                if (saathiMatch && saathiMatch.ward && Array.isArray(saathiMatch.ward)) {
                    saathiMatch.ward.forEach((ward) => {
                        if (wardData[ward]) {
                            wardData[ward].present += 1;
                            wardData[ward].absent = wardData[ward].total - wardData[ward].present;
                        }
                    });
                }
            });
        }
        const resultArray = Object.values(wardData);
        return resultArray;
    });
}


export function getStaffMeta() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathis_meta")
        .doc("saathisMeta")
        .get();
}


export function updateStaffMeta(authUid, newThumbnailUrl, newImageUrl) {
    const munId = window.localStorage.mun;

    return db
        .collection("mun")
        .doc(munId)
        .collection("saathis_meta")
        .doc("saathisMeta")
        .get()
        .then((doc) => {
            if (doc.exists) {
                const data = doc.data().data;

                const updatedData = Object.keys(data).map(saathiKey => {
                    const saathi = data[saathiKey];
                    if (saathi.authUid === authUid) {
                        return {
                            ...saathi,
                            thumbnailUrl: newThumbnailUrl,
                            imageUrl: newImageUrl
                        };
                    }
                    return saathi;
                });

                return db
                    .collection("mun")
                    .doc(munId)
                    .collection("saathis_meta")
                    .doc("saathisMeta")
                    .update({ data: updatedData });
            } else {
                throw new Error("Document not found");
            }
        });
}

export const storeSaathiMetaData = async (obj) => {
    try {
        await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("saathis_meta")
            .doc("saathisMeta")
            .update({ data: obj });
        return { success: true, message: 'Saathi meta data successfully updated.' };
    } catch (error) {
        console.error("Error updating saathi meta data:", error);
        return { success: false, message: 'Error updating saathi meta data. Please try again.' };
    }
}

export const getApprovedSaathi = async (ward) => {
    let staffRef = getSaathiRef().where("isApproved", "==", true);
    if (!ward) {
        return staffRef.get();
    } else {
        return staffRef.where("ward", "array-contains", ward).get()
    }

}

/*export function getKarmi() {
  return db
    .collection("mun")
    .doc(window.localStorage.mun)
    .collection("karmi")
    .get();
}*/

export function getCommercials(ward) {
    if (!ward) {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("commercials")
            .get();
    } else {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("commercials")
            .where("ward_id", "==", ward)
            .get();
    }
}

export function getWardCommercials(ward) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("commercials")
        .where("ward_id", "==", ward)
        .get();
}

export function updateCommercial(id, data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("commercials")
        .doc(id)
        .set(data, { merge: true })
}

export function deleteCommercial(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("commercials")
        .doc(id)
        .delete()
}


export function getWardSaathi(ward) {
    let item
    if (ward) {
        item = ward
    }
    if (ward.length === 0) {
        item = ['null']
    }
    //  console.log('i',item)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .where("areaCode", "in", item)
        .get();
}

export function getWardKarmi(ward) {
    let item
    if (ward) {
        item = ward
    }
    if (ward.length === 0) {
        item = ['null']
    }
    //  console.log('i',item)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmi")
        .where("areaCode", "in", item)
        .get();
}

export function fetchSaathi() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        // .where("isApproved", "==", true)
        .get();
}

export function fetchSupervisor(supID) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(supID)
        .get();
}

export function getWardStaff(passedWard, supID) {
    const staffQuery = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .where("ward", "array-contains", passedWard)
        .where("isSupervisor", "==", false)
        .get();

    const supDocRef = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(supID);

    return Promise.all([staffQuery, supDocRef.get()])
        .then((results) => {
            const staffDocs = results[0].docs;
            const supDocData = results[1].data();
            const updatePromises = staffDocs.map((doc) => {
                const staffData = doc.data();

                if (staffData.oldSupervisor) {
                    staffData.supervisor = staffData.oldSupervisor || {};
                    staffData.supervisor_name = staffData.oldSupervisor.name || "Admin";
                    delete staffData.oldSupervisor;
                    const staffDocRef = doc.ref;
                    return staffDocRef.set(staffData);
                }
                return Promise.resolve();
            });

            return Promise.all(updatePromises)
                .then(() => {
                    if (supDocData && supDocData.assignedExtraWard === passedWard) {
                        delete supDocData.assignedExtraWard;
                        if (supDocData.saathi_list) {
                            staffDocs.forEach((doc) => {
                                const staffData = doc.data();
                                const staffAuthUid = staffData.authUid;
                                supDocData.saathi_list = supDocData.saathi_list.filter((saathi) => saathi.authUid !== staffAuthUid);
                            });
                        }
                        return supDocRef.set(supDocData)
                            .then(() => {
                                console.log("Supervisor and staff documents reverted successfully");
                            })
                            .catch((error) => {
                                console.error("Error updating document:", error);
                            });
                    }
                });
        })
        .catch((error) => {
            console.error("Error fetching data:", error);
        });
}

export function updateSaathiAssignExtraWard(supID, extraWard) {

    const staffQuery = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .where("ward", "array-contains", extraWard)
        .where("isSupervisor", "==", false)
        .get();

    const supDocRef = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(supID);

    return staffQuery
        .then((staffQuerySnapshot) => {
            return supDocRef.get().then((supDoc) => {
                const existingSaathiList = supDoc.data().saathi_list || [];
                const updatePromises = [];
                staffQuerySnapshot.forEach((staffDoc) => {
                    const staffData = staffDoc.data();
                    const { authUid, phoneNumber, supervisor } = staffData;
                    const oldSupervisor = supervisor ? { ...supervisor } : null;
                    const updatedSupervisor = {
                        authUid: supDoc.data().authUid,
                        name: supDoc.data().name,
                    };
                    const staffUpdatePromise = staffDoc.ref
                        .update({
                            oldSupervisor,
                            supervisor: updatedSupervisor,
                            supervisor_name: supDoc.data().name,
                        })
                        .then(() => staffDoc.ref.get());
                    updatePromises.push(staffUpdatePromise);
                });
                return Promise.all(updatePromises).then((updatedStaffDocs) => {
                    const updatedStaffData = updatedStaffDocs.map((doc) => ({
                        authUid: doc.data().authUid,
                        phoneNumber: doc.data().phoneNumber,
                        imageUrl: doc.data().imageUrl || "",
                        name: doc.data().name,
                        id: doc.data().authUid,
                    }));
                    const updatedSaathiList = [...existingSaathiList, ...updatedStaffData];
                    return supDocRef.update({
                        saathi_list: updatedSaathiList,
                        assignedExtraWard: extraWard,
                    });
                });
            });
        })
        .then(() => {
            console.log("Supervisor and staff documents updated successfully");
        })
        .catch((error) => {
            console.error("Error updating documents:", error);
        });
}



export function fetchCtpts() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("ctpt")
        .get();
}

export function fetchKarmiAttendance(date) {
    // console.log("date", date)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmiGeo")
        .doc(date)
        .collection("attendance")
        .get()
}

export function getSaathiAttendanceInfo(date, saathi) {
    //console.log("date,saathi:", date,saathi)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathiGeo")
        .doc(date)
        .collection("attendance")
        .doc(saathi)
        .get()
}

export function fetchSaathiImage(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathiGeo")
        .doc(date)
        .collection("images")
        .get()
}

export function fatchSaathiAcknowlwdge(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("acknowledge")
        .doc(date)
        .collection("acknowledge")
        .get()
}

export function getAttendanceOfSaathi(date, id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathiGeo")
        .doc(date)
        .collection("attendance")
        .doc(id)
        .get()
}

export function fetchSaathiLocation(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathiGeo")
        .doc(date)
        .collection("saathi")
        .get()
}

export async function markPresent(date, id, dutyOnTime, dutyOffTime, staffName) {
    try {
        const dutyOnTimeParts = dutyOnTime.split(':');
        const dutyOffTimeParts = dutyOffTime.split(':');
        const dateParts = date.split('-');
        const year = parseInt(dateParts[2], 10);
        const month = parseInt(dateParts[1], 10) - 1;
        const day = parseInt(dateParts[0], 10);
        const dutyOnDate = new Date(year, month, day, parseInt(dutyOnTimeParts[0]), parseInt(dutyOnTimeParts[1]));
        const dutyOffDate = new Date(year, month, day, parseInt(dutyOffTimeParts[0]), parseInt(dutyOffTimeParts[1]));
        const DutyOnTimestamp = dutyOnDate.getTime();
        const DutyOffTimestamp = dutyOffDate.getTime();
        const reverse_date = date.split("-").reverse().join("-");
        const attendanceDocRef = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("saathiGeo")
            .doc(reverse_date)
            .collection("attendance")
            .doc(id);
        const attendanceSnapshot = await attendanceDocRef.get();
        if (attendanceSnapshot.exists) {
            console.log("ID is found in saathigeo>attendance collection.", staffName, id, date);
            message.error({ content: `Attendance is already marked as present for ${staffName} on date ${date}.`, duration: 2 });
        } else {
            console.log("ID not found in saathigeo>attendance collection.");
            await attendanceDocRef.set({
                status: [
                    { timestamp: DutyOnTimestamp, status: true },
                    { timestamp: DutyOffTimestamp, status: false }
                ]
            });
            message.success({ content: `Attendance marked as present successfully for ${staffName} on date ${date}.`, duration: 2 });
        }
    } catch (error) {
        console.error("Error occurred while marking attendance:", error);
        message.error({ content: 'An error occurred while marking attendance. Please try again later.', duration: 2 });
    }
}



export async function checkIfIdPresentAndDelete(date, id, staffName) {
    let reverse_date = date.split("-").reverse().join("-");
    try {
        const currentDate = new Date();
        const currentDateIST = new Date(currentDate.getTime() + (5.5 * 60 * 60 * 1000));
        const currentDateFormatted = currentDateIST.toISOString().split('T')[0];
        if (reverse_date === currentDateFormatted) {
            await db
                .collection("mun")
                .doc(window.localStorage.mun)
                .collection("saathi")
                .doc(id)
                .set({ status: false }, { merge: true });
            message.success({ content: `Status is set to false successfully for ${staffName} on date ${date}.`, duration: 2 });
        }
        const attendanceDocRef = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("saathiGeo")
            .doc(reverse_date)
            .collection("attendance")
            .doc(id);
        const saathiDocRef = db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("saathiGeo")
            .doc(reverse_date)
            .collection("saathi")
            .doc(id);
        const [attendanceSnapshot, saathiSnapshot] = await Promise.all([
            attendanceDocRef.get(),
            saathiDocRef.get()
        ]);
        if (attendanceSnapshot.exists) {
            console.log("ID is found in saathigeo>attendance collection.");
            await attendanceDocRef.delete();
            message.success({ content: `Attendance data deleted successfully fot ${staffName} on date ${date}.`, duration: 2 });
        } else {
            console.log("ID not found in saathigeo>attendance collection.");
            message.error({ content: `Attendance data not found.`, duration: 2 });
        }
        if (saathiSnapshot.exists) {
            console.log("ID is found in saathigeo>saathi collection.");
            await saathiDocRef.delete();
            message.success({ content: `Location data deleted successfully for ${staffName} on date ${date}.`, duration: 2 });
        } else {
            console.log("ID not found in saathigeo>saathi collection.");
            message.error({ content: `Location data not found.`, duration: 2 });
        }
    } catch (error) {
        console.error("Error in checkIfIdPresentAndDelete:", error);
    }
}

export function fetchKarmiLocation(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("karmiGeo")
        .doc(date)
        .collection("karmi")
        .get()
}


export function addSaathi(id, data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(id)
        .set(data);
}

export async function isSaathiExists(id) {
    if (id === null) {
        console.warn("ID is null. Returning null.");
        return null;
    }

    try {
        const saathiDoc = await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("saathi")
            .doc(id)
            .get();

        if (saathiDoc.exists) {
            return db
                .collection("mun")
                .doc(window.localStorage.mun)
                .collection("saathi")
                .doc(id)
                .get();
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error retrieving saathi data:", error);
    }
}


export function saathiExist(phoneNumber) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .where("phoneNumber", "==", phoneNumber)
        .get();
}

export function updateSaathi(id, updateObj) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(id)
        .set({
            id: updateObj.id,
            areaCode: updateObj.ward,
            name: updateObj.name,
            email: updateObj.email,
            father_name: updateObj.father_name,
            password: CryptoJS.AES.encrypt(updateObj.password || '', HASH_KEY).toString(),
            phoneNumber: updateObj.phoneNumber,
            isApproved: updateObj.isApproved,
            ward: updateObj.ward,
            userId: updateObj.userId,
            staffType: updateObj.staffType,
            isSupervisor: updateObj.isSupervisor,
            hasFuelPermission: updateObj.hasFuelPermission,
            saathi_list: updateObj.saathi_list,
            imageUrl: updateObj.imageUrl,
            thumbnailUrl: updateObj.thumbnailUrl || ""
        },
            { merge: true });
}

export function updateSuperVisorOfSaathi(id, supervisor, name) {

    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(id)
        .update({
            supervisor: supervisor,
            supervisor_name: name
        });
}

export function updateSuperVisorDoc(id, array_list) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(id)
        .update({
            saathi_list: array_list
        });
}

export function saathiSetting(id, data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("manager")
        .doc(id)
        .set(data)
}

export function getSaathiManager() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("manager")
        .get()
}

export function updateCheckpoint(id, updateObj) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("checkpoints")
        .doc(id)
        .update(updateObj);
}
export function removeRfid(ch_id, updateObj) {
    // Update the documents based on the where clause
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("rfidDevices")
        .where("checkgateName", "==", ch_id)
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                doc.ref.update(updateObj);
            });
        })
        .catch(error => {
            console.error("Error updating documents: ", error);
        });
}
export function updateRfid(id, updateObj) {
    if(id == ""){
        return
    }
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("rfidDevices")
        .doc(id)
        .update(updateObj);
}

export function updatePlace(id, updateObj) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("places")
        .doc(id)
        .update(updateObj);
}

export function deleteSaathi(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("saathi")
        .doc(id)
        .delete()
}

export function getHouseholdspayment() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("households")
        .get()
}

export function fetchWardHousehold(area) {
    if (area) {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("households")
            .where("ward_id", "==", area)
            .get()
    }
}

export function getWardHousehold(area) {
    if (area) {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("households")
            .where("ward_id", "==", area)
            .get()
    }
}

const getAcknowledgeRef = (mun, date, ward) => {
    let query = db.collection("mun")
        .doc(mun)
        .collection("acknowledge")
        .doc(date)
        .collection("acknowledge");
    if (ward) {
        query = query.where("ward_id", "==", ward)
    }
    return query;
}
export function getWardResidentAck(date, area) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("acknowledge")
        .doc(date)
        .collection("acknowledge")
        .where("ward_id", "==", area)
        .get()

}


const getFuelDataRef = () => {
    return db.collection("mun").doc(window.localStorage.mun).collection("fuel");
};

export const getFuelDataByDate = async (date) => {
    const fuelDocs = [];

    try {
        const fuelCollectionRef = getFuelDataRef().doc(date).collection("fuel");
        const fuelQuerySnapshot = await fuelCollectionRef.get();

        fuelQuerySnapshot.forEach((doc) => {
            fuelDocs.push(doc.data());
        });
    } catch (error) {
        console.error("Error fetching fuel data:", error);
    }

    return fuelDocs;
};

const getFuelRequestRef = () => {
    return db.collection("mun").doc(window.localStorage.mun).collection("fuel_management")
}

export const getFuelRequests = () => {
    return getFuelRequestRef().get();
}

export const updateFuelRequest = (obj) => {
    return getFuelRequestRef().doc(obj.doc_id).update(obj);

}
export const getFuelRequestData = (id, date) => {
    return getFuelRequestRef()
        .where("date", "==", date)
        .where("vehicle_id", "==", id)
        .get();
}

export const getFuelRequestDataByDate = (date) => {
    return getFuelRequestRef()
        .where("date", "==", date)
        .get();
}

export const getHouseholdCount = async (da) => {
    let obj = {};
    try {
        const [households, acks] = await Promise.all([
            db.collection("mun")
                .doc(window.localStorage.mun)
                .collection("users").get(),
            getAcknowledgeRef(window.localStorage.mun, da).get()
        ]);
        acks.forEach((usersDoc) => {
            let usersData = usersDoc.data();
            let ward_id = usersData.ward_id;
            if (obj[ward_id] === undefined) {
                obj[ward_id] = {
                    "attendance": 1,
                    "acky": usersData["acknowledge"] ? 1 : 0,
                    "ackn": !usersData["acknowledge"] ? 1 : 0,
                    "segy": usersData["segregation"] ? 1 : 0,
                    "segn": !usersData["segregation"] ? 1 : 0,
                    "userCount": 0
                };
            } else {
                obj[ward_id]["attendance"] = obj[ward_id]["attendance"] + 1
                obj[ward_id]["acky"] = usersData["acknowledge"] ?
                    obj[ward_id]["acky"] + 1 : obj[ward_id]["acky"];
                obj[ward_id]["ackn"] = !usersData["acknowledge"] ?
                    obj[ward_id]["ackn"] + 1 : obj[ward_id]["ackn"];
                obj[ward_id]["segy"] = usersData["segregation"] ?
                    obj[ward_id]["segy"] + 1 : obj[ward_id]["segy"];
                obj[ward_id]["segn"] = !usersData["segregation"] ?
                    obj[ward_id]["segn"] + 1 : obj[ward_id]["segn"];
            }
        });
        households.forEach((houseDoc) => {

            let data = houseDoc.data();
            let ward_id = data.ward_id || `WARD ${data?.WARD_NO}`;
            if (obj[ward_id] === undefined) {
                obj[ward_id] = {
                    "attendance": 0,
                    "acky": 0,
                    "ackn": 0,
                    "segy": 0,
                    "segn": 0,
                    "userCount": 0,
                };
            } else {
                obj[ward_id]["userCount"] = obj[ward_id]["userCount"] + 1;
            }
        });
        return obj;
    } catch (err) {
        return obj;
    }
}
export const getComplaintRef = () => {
    return db.collection("mun").doc(window.localStorage.mun).collection("complaints")
}

export const getAllHouseholds = () => {
    return db.collection("mun").doc(window.localStorage.mun).collection("households")
}

export const getCitizensCount = () => {
    return db.collection("mun").doc(window.localStorage.mun).collection("households").get()
}

export const storeHouseholdCitizenMetaData = (obj) => {

    const db = firebase.firestore();
    const munDocRef = db.collection("mun").doc(window.localStorage.mun);
    const householdMetaDataRef = munDocRef.collection("household_meta_data");

    return Promise.all(obj.map((ward) => {
        const wardRef = householdMetaDataRef.doc(ward.ward_id);
        return wardRef.set(ward);
    }))
        .then(() => {
            console.log("Data stored successfully");
        })
        .catch((error) => {
            console.error("Error storing data: ", error);
        });
}

export const getHouseholdMetaData = () => {
    return db.collection("mun").doc(window.localStorage.mun).collection("household_meta_data").get()
}





export const getUsersCount = () => {
    return db.collection("mun").doc(window.localStorage.mun).collection("users").get()
}

export const getTaskRef = () => {
    return db.collection("mun").doc(window.localStorage.mun).collection("tasks")
}

export const getWardWiseHouseholds = async () => {
    let obj = {}

    try {
        let households_list = await getAllHouseholds().get();

        households_list.forEach((each_hh) => {
            let HH_Data = each_hh.data();
            let ward_id = HH_Data.ward_id;
            if (obj[ward_id] === undefined) {
                obj[ward_id] = 1
            } else {
                obj[ward_id] = obj[ward_id] + 1;
            }
        });
        return obj;
    } catch (err) {
        return obj;
    }

}

export const getWardWiseComplaints = async () => {
    let obj = {}

    try {
        const [C_Array, W_Array] = await Promise.all([
            getComplaintRef().get(),
            fetchWards()
        ]);
        C_Array.forEach((complaint) => {
            let C_Data = complaint.data();
            let ward_id = C_Data.ward_id || C_Data.ward;
            if (obj[ward_id] === undefined) {
                obj[ward_id] = 1
            } else {
                obj[ward_id] = obj[ward_id] + 1;
            }
        });
        W_Array.forEach((wardDoc) => {
            let data = wardDoc.data();
            let ward_id = data.name;
            if (obj[ward_id] === undefined) {
                obj[ward_id] = 0;
            }
        });
        return obj;
    } catch (err) {
        return obj;
    }

}
export const getWardWiseTasks = async () => {
    let obj = {}

    try {
        const [T_Array, W_Array] = await Promise.all([
            getTaskRef().get(),
            fetchWards()
        ]);
        T_Array.forEach((task) => {
            let T_Data = task.data();
            let ward_id = T_Data.ward_id || T_Data.ward;
            if (obj[ward_id] === undefined) {
                obj[ward_id] = 1
            } else {
                obj[ward_id] = obj[ward_id] + 1;
            }
        });
        W_Array.forEach((wardDoc) => {
            let data = wardDoc.data();
            let ward_id = data.name;
            if (obj[ward_id] === undefined) {
                obj[ward_id] = 0;
            }
        });
        return obj;
    } catch (err) {
        return obj;
    }

}

export const getDateWiseComplaints = async (c_date) => {
    let Dummy = ["ACTIVE", "ASSIGNED", "PENDING"];
    let obj = {}

    try {
        const [C_Array, W_Array] = await Promise.all([
            getComplaintRef().where("created_date", "==", c_date).get(),
            fetchWards()
        ]);
        C_Array.forEach((complaint) => {
            let C_Data = complaint.data();
            let ward_id = C_Data.ward_id || C_Data.ward;
            if (obj[ward_id] === undefined) {
                obj[ward_id] = {
                    "open": Dummy.includes(C_Data["state"]) ? 1 : 0,
                    "closed": C_Data["state"] === "CLOSED" ? 1 : 0
                };
            } else {
                obj[ward_id]["open"] = Dummy.includes(C_Data["state"]) ?
                    obj[ward_id]["open"] + 1 : obj[ward_id]["open"];
                obj[ward_id]["closed"] = C_Data["state"] === "CLOSED" ?
                    obj[ward_id]["closed"] + 1 : obj[ward_id]["closed"];
            }
        });
        W_Array.forEach((wardDoc) => {
            let data = wardDoc.data();
            let ward_id = data.name;
            if (obj[ward_id] === undefined) {
                obj[ward_id] = {
                    "open": 0,
                    "closed": 0
                };
            }
        });
        return obj;
    } catch (err) {
        return obj;
    }

}

export const getDateWiseCtpt = async (c_date) => {
    let obj = {}

    try {
        const [Ctpt_Array, Ack_Ctpt, W_Array] = await Promise.all([
            fetchCtpts(),
            fetchCtptsAttendance(c_date),
            fetchWards()
        ]);
        Ack_Ctpt.forEach((ctpt) => {
            let C_Data = ctpt.data();
            let ward_id = C_Data.areaCode;
            if (obj[ward_id] === undefined) {
                obj[ward_id] = {
                    "attended": 1,
                    "photoCount": C_Data?.imageUrl ? 1 : 0,
                    "totalCount": 0
                };
            } else {
                obj[ward_id]["attended"] = obj[ward_id]["attended"] + 1;
                obj[ward_id]["photoCount"] = C_Data?.imageUrl ?
                    obj[ward_id]["photoCount"] + 1 :
                    obj[ward_id]["photoCount"];

            }
        });
        Ctpt_Array.forEach((wardDoc) => {
            let data = wardDoc.data();
            let ward_id = data.ward_id;
            if (obj[ward_id] === undefined) {
                obj[ward_id] = {
                    "attended": 0,
                    "totalCount": 1,
                    "photoCount": 0
                };
            } else {
                obj[ward_id]["totalCount"] = obj[ward_id]["totalCount"] + 1;
            }
        });
        W_Array.forEach((wardDoc) => {
            let data = wardDoc.data();
            let ward_id = data.name;
            if (obj[ward_id] === undefined) {
                obj[ward_id] = {
                    "attended": 0,
                    "totalCount": 0,
                    "photoCount": 0
                };
            }
        });
        return obj;
    } catch (err) {
        return obj;
    }

}

export function getUserPayments(statusType, date, ward, saathi) {
    let year_month = date.split("-")
    let year = parseInt(year_month[0]);
    let month = year_month[1];
    if (statusType == "Paid") {
        // console.log('year month', year, month)
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("payments")
            .where("month", "==", month)
            .where("year", "==", year)
            .where("ward_id", "==", ward)
            .get()
    }
}

export function getPayments(startDaterange, endDaterange) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("payments")
        .where("payment_received_date", ">=", startDaterange)
        .where("payment_received_date", "<=", endDaterange)
        .get()
}

export function getHouseholds(ward) {
    if(!ward){
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("households")
        .get()
    } else {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("households")
            .where("ward_id", "==", ward)
            .get();
    }
}

export function getHouseholdsAttendance(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("acknowledge")
        .doc(date)
        .collection("acknowledge")
        .get()
}

export function getHouseholdsAttendanceDash(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("acknowledge")
        .doc(date)
        .collection("acknowledge")
}


export const getTabs = async () => {
    let val = {};
    await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .get()
        .then(async (snapshot) => {
            let data = snapshot.data();
            val = data.tabs;
        });

    return val;
};

export async function addTaskSettings(data) {
    let val = await db

        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                Task_Types: data.task,

            },
            { merge: true }
        );

    return val;
}

export async function addDashboardSettings(data) {
    let val = await db

        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                name: data.name,
                time_offlineVehicle: data.time_offlineVehicle,
                phoneNumber_offlineVehicle: data.offlineVehiclePhoneNum,
                dashboard_switch_time: data.dashboard_switch_time,
                settings: {
                    alert_radius: data.alert_radius,
                    arrow_vehicle_path: data.arrow_vehicle_path,
                    sunday_filter: data.sunday_filter,
                    household_data_from_cache: data.household_data_from_cache,
                    auth_type_selection: data.auth_type_selection,
                    auto_duty_off_time: data.auto_duty_off_time,
                },
            },
            { merge: true }
        );

    return val;
}

export async function DeleteOffPhnFromSettings(data) {
    let val = await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                phoneNumber_offlineVehicle: data,
            },
            { merge: true }
        );

    return val;
}

export async function addNewOfflinePhnNumSetting(data) {
    let val = await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                phoneNumber_offlineVehicle: data,
            },
            { merge: true }
        );

    return val;
}


export async function addPaymentSettings(data) {
    let val = await db

        .collection("mun")
        .doc(window.localStorage.mun)
        .set({
            payments: {
                monthly: data.monthly,
                quarterly: data.quarterly,
                half_yearly: data.half_yearly,
                yearly: data.yearly,
            }
        },
            { merge: true });
    return val;
}

export async function addAcknowledgeSettings(data) {
    let val = await db

        .collection("mun")
        .doc(window.localStorage.mun)
        .set({
            acknowledgeType: data.acknowledgeType,
            acknowledge_email: data.acknowledge_email,
        },
            { merge: true });
    return val;
}


export async function addTabsSettings(data) {
    let val = await db

        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                tabs: data.tabs
            },
            { merge: true }
        );

    return val;
}

export async function addCheckpointSettings(data) {
    let val = await db

        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                Checkpoint_Types: data.checkpoints,
                settings: {
                    checkpoints_alert_radius: data.checkpoints_alert_radius || "",
                    daily_report_check_points: data.daily_report_check_points || "",
                },
            },
            { merge: true }
        );

    return val;
}


export async function addCheckpointAlertRadiusSetting(data) {
    let val = await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .set({
            settings: {
                checkpoints_alert_radius: data.checkpoints_alert_radius,
            },
        },
            { merge: true }
        );
    return val;
}

export async function addDailyReportCheckpointSetting(NewlyAddedReportMail) {
    // console.log("d", NewlyAddedReportMail)
    let val = await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                settings: {
                    daily_report_check_points: NewlyAddedReportMail,
                },
            },
            { merge: true }
        );
    return val;
}

export async function addDailyReportCheckGateSetting(NewlyAddedReportMail) {
    let val = await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                settings: {
                    daily_report_check_gate: NewlyAddedReportMail,
                },
            },
            { merge: true }
        );
    return val;
}

export async function addCheckpointTypeSetting(NewlyAddedCheckpoint) {
    // console.log("d", NewlyAddedCheckpoint)
    let val = await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .set({ Checkpoint_Types: NewlyAddedCheckpoint },
            { merge: true }
        );
    return val;
}

export async function getCheckpointTypes() {
    let doc = await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .get();

    if (doc.exists) {
        return doc.data().Checkpoint_Types || [];
    } else {
        return [];
    }
}


export async function addCheckgateSettings(data) {
    let val = await db

        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                settings: {
                    checkgate_alert_radius: data.checkgate_alert_radius,
                    daily_report_check_gate: data.daily_report_check_gate,
                },
            },
            { merge: true }
        );

    return val;
}

export async function addSaathitimeinterval(data) {
    let val = await db

        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                SaathitimeInterval: data,

            },
            { merge: true }
        );

    return val;
}


export async function addReportSettings(data) {
    let val = await db

        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                settings: {
                    daily_report_vehicles: data.daily_report_vehicles,
                },
            },
            { merge: true }
        );

    return val;
}

export async function addSaathiEmail(data) {
    let val = await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                settings: {
                    daily_report_saathi: data,
                },
            },
            { merge: true }
        );
    return val;
}

export function addComplainTypeSettings(data) {
    // console.log("api com setting data",data)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("settings_web")
        .doc("complaints")
        .set({ complain_types: data }, { merge: true });
}


export function fetchComplainSettings() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("settings_web")
        .doc("complaints")
        .get()
}

export function fetchTaskSettings() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("settings_web")
        .doc("tasks")
        .get()
}

export function addTasksTypeSettings(data) {
    // console.log("api com setting data",data)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("settings_web")
        .doc("tasks")
        .set({ tasks_types: data }, { merge: true });
}


export async function addComplaintSettings(data) {
    let val = await db

        .collection("mun")
        .doc(window.localStorage.mun)
        .set(
            {
                Complaint_Types: data.complaints,

            },
            { merge: true }
        );

    return val;
}

export function getAppsetting() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("settings_app")
        .get()
}

export const getAppsettingsId = async () => {
    let data = await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("settings_app")
        .get()
    return data?.docs[0].id;
}

const getAcknowledgeIntervalRef = () => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("settings_app")
        .doc(window.localStorage.mun)
        .collection("acknowledge_time")
}

const wasteEstimationRef = () => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("settings_app")
        .doc(window.localStorage.mun)
        .collection("wasteEstimation");
}

export const editDryWasteEstimation = async (value) => {
    console.log("value Dry: ", value);
    const dryWasteDocRef = wasteEstimationRef().doc("dryWaste");
    await dryWasteDocRef.set({ value }, { merge: true });
};

export const editWetWasteEstimation = async (value) => {
    console.log("value Wet: ", value);
    const wetWasteDocRef = wasteEstimationRef().doc("wetWaste");
    await wetWasteDocRef.set({ value }, { merge: true });
};

export const editCommercialWasteEstimation = async (value) => {
    console.log("value Commercial: ", value);
    const commercialWasteDocRef = wasteEstimationRef().doc("commercialWaste");
    await commercialWasteDocRef.set({ value }, { merge: true });
};

export function getWasteEstimationDocuments() {
    return wasteEstimationRef().get()
}

export function saveWasteEstimationProcessingCenterData(date, data) {
    const dataObject = Array.isArray(data) ? { items: data } : data;

    const docRef = db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("processingCenterWasteData")
        .doc(date);

    return docRef
        .set(dataObject, { merge: true })
        .then(() => {
            console.log("Data saved successfully for date:", date);
        })
        .catch((error) => {
            console.error("Error saving data for date", date, ":", error);
        });
}

export function getWasteEstimationProcessingCenterData(date) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("processingCenterWasteData")
        .doc(date)
        .get()
        .then((doc) => {
            if (doc.exists) {
                const data = doc.data();
                if (data && data.items) {
                    console.log("Data for date", date, "retrived successfully.");
                    return data.items;
                } else {
                    console.log("Document for date", date, "exists but is empty.");
                    return null;
                }
            } else {
                console.log("Document for date", date, "does not exist.");
                return null;
            }
        })
        .catch((error) => {
            console.error("Error getting document in getWasteEstimationProcessingCenterData for date: ", date, "error is: ", error);
        });
}

const getAttendanceIntervalRef = () => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("settings_app")
        .doc(window.localStorage.mun)
        .collection("attendance_time")
}

export function getAckIntervals() {
    return getAcknowledgeIntervalRef().get()
}

export function updateAcknowledgeInterval(obj, document) {
    return getAcknowledgeIntervalRef().doc(document.id).set(obj, { merge: true });
}

export function DeleteAcknowledgeInterval(id) {
    return getAcknowledgeIntervalRef().doc(id).delete();
}

export function addAcknowledgeInterval(obj) {
    return getAcknowledgeIntervalRef().add(obj);
}

export function getAttendanceIntervals() {
    return getAttendanceIntervalRef().get()

}

export function updateAttendanceInterval(obj, document) {
    return getAttendanceIntervalRef().doc(document.id).set(obj, { merge: true });
}

export function DeleteAttendanceInterval(id) {
    return getAttendanceIntervalRef().doc(id).delete();
}

export function addAttendanceInterval(obj) {
    return getAttendanceIntervalRef().add(obj);
}
export function saveSettingAppConfig(docId, data) {
    if (docId != "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc(docId)
            .set({
                app_config: data
            },
                { merge: true }
            );
    } else if (docId == "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc()
            .set({
                app_config: data
            },
                { merge: true }
            );

    }

}

export function saveNotifDefaultMsg(docId, data) {
    if (docId != "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc(docId)
            .set({
                notif_default_msg: data
            },
                { merge: true }
            );
    } else if (docId == "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc()
            .set({
                notif_default_msg: data
            },
                { merge: true }
            );

    }

}


export function addLanguage(docId, data) {
    if (docId != "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc(docId)
            .set({
                language: data
            },
                { merge: true }
            );
    } else if (docId == "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc()
            .set({
                language: data
            },
                { merge: true }
            );
    }
}

export function saveAboutUs(docId, data) {
    if (docId != "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc(docId)
            .set({
                aboutUs: data
            },
                { merge: true }
            );
    } else if (docId == "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc()
            .set({
                aboutUs: data
            },
                { merge: true }
            );
    }
}


export function invokeSwitchAboutUs(docId, data) {

    // console.log("id and about us == ", docId, data)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("settings_app")
        .doc(docId)
        .set({
            aboutUs: data
        },
            { merge: true }
        );
}


export function saveContacts(docId, data) {
    // console.log("d", data)
    if (docId != "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc(docId)
            .set({
                contacts: data.contacts
            },
                { merge: true }
            );
    } else if (docId == "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc()
            .set({
                contacts: data.contacts
            },
                { merge: true }
            );
    }
}

export function saveOtherContacts(docId, data) {
    if (docId != "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc(docId)
            .set({
                otherContacts: data.EditedOtherContacts
            },
                { merge: true }
            );
    } else if (docId == "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc()
            .set({
                otherContacts: data.EditedOtherContacts
            },
                { merge: true }
            );

    }

}

export function addAppTabsSettings(docId, data) {
    if (docId != "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc(docId)
            .set({
                tabs: data.tabs
            },
                { merge: true }
            );
    } else if (docId == "no") {
        return db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("settings_app")
            .doc()
            .set({
                tabs: data.tabs
            },
                { merge: true }
            );
    }
}

export function addNewCitizenTag(data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("settings_app")
        .doc(window.localStorage.mun)
        .update({
            userlist: data
        });

}

export function getGroupRoutes(name) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("route_group")
        .where("name", "==", name)
        .get()
}

export function getRoutes_group(name) {
    return db
        .doc(window.localStorage.mun)
        .collection("routes")
        .where("name", "==", name)
        .get()
}

export function fetchRoutes() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("routes")
        .get()
}

export function fetchRoutesGroup() {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("route_group")
        .get()
}

// export function addRoute(data) {
//     return db
//         .collection("mun")
//         .doc(window.localStorage.mun)
//         .collection("routes")
//         .doc()
//         .set(data);
// }

export async function addRoute(data) {
    const createdDate = new Date().toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });

    const routeRef = db.collection("mun")
        .doc(window.localStorage.mun)
        .collection("routes")
        .doc();

    const newData = {
        ...data,
        createdDate: createdDate,
        routeId: routeRef.id
    };

    try {
        await routeRef.set(newData);
        alert("Route saved successfully!");
        return routeRef.id;
    } catch (error) {
        alert("Error saving route!");
        console.error("Error saving route: ", error)
        return null;
    }
}

export async function setWardRouteID(docID, routeId) {
    const wardsRef = db.collection("mun").doc(window.localStorage.mun).collection("wards").doc(docID);
    try {
        await wardsRef.set({
            routeId: routeId
        }, { merge: true });
        alert("RouteID added to ward successfully!");
    } catch (error) {
        alert("Error adding RouteID to ward!" + error);
        console.error("Error adding RouteID to ward: ", error)
    }
}



export async function getWardID(id) {
    const wardsRef = db.collection("mun").doc(window.localStorage.mun).collection("wards");
    const querySnapshot = await wardsRef.where("id", "==", id).get();
    if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        return doc.id;
    } else {
        return null;
    }
}


export function saveEditRoute(id, data) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("routes")
        .doc(id)
        .set({ name: data.name, distance: data.distance }, { merge: true });

}

const getApplicationRef = (muncipality) => {
    return db.collection("mun").doc(muncipality).collection("application");
}

export function getStaffAppUrl() {
    return getApplicationRef(window.localStorage.mun).doc("staff").collection("apps").get();
}

export function getCitizenAppUrl() {
    return getApplicationRef(window.localStorage.mun).doc("citizen").collection("apps").get();
}

export function addStaffAppUrl(obj) {
    return getApplicationRef(window.localStorage.mun).doc("staff").collection("apps").add(obj);
}

export function addCitizenAppUrl(obj) {
    return getApplicationRef(window.localStorage.mun).doc("citizen").collection("apps").add(obj);
}

export function updateShortAppUrl(docName, id, shortUrl) {
    return getApplicationRef(window.localStorage.mun)
        .doc(docName)
        .collection("apps")
        .doc(id)
        .get()
        .then(docSnapshot => {
            if (docSnapshot.exists) {
                return docSnapshot.ref.update({
                    shortAppUrl: shortUrl
                })
                    .then(() => {
                        console.log("Short App URL updated successfully!");
                    })
                    .catch(error => {
                        throw new Error("Error updating shortAppUrl:", error);
                    });
            } else {
                throw new Error("Document with the provided ID not found");
            }
        })
        .catch(error => {
            console.error(error.message);
        });
}

export function getTenant() {
    return db.collection("Tenant").get();
}

export const getSettings = async () => {
    let val = {};

    await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .get()
        .then(async (snapshot) => {
            let data = snapshot.data();
            val = data;
        });

    return val;
};

export function getCustomizeCitizens(startDate, endDate) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("users")
        .where("date", ">=", startDate)
        .where("date", "<=", endDate)
        .get();

}

export function getCustomizeCommercials(startDate, endDate) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("commercials")
        .where("date", ">=", startDate)
        .where("date", "<=", endDate)
        .get();

}

export function deleteUsers(id) {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("console_users")
        .doc(id)
        .delete();
}

export const getUserConfig = async (userId) => {
    if (window.localStorage.userConfig) {
        return JSON.parse(window.localStorage.userConfig)
    }
    let val = {};
    await db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("console_users")
        .doc(userId)
        .get()
        .then(async (snapshot) => {
            val = snapshot.data();
        });
    // console.log("val: ", val)
    if (val) {
        window.localStorage.userConfig = JSON.stringify(val)
    }
    return val;

};

export function login(user) {
    return firebaseAuth
        .signInWithEmailAndPassword(user.username, user.password)
        .then(async (userInfo) => {
            // console.log('u',userInfo.user.uid)
            return await db
                .collection("mun")
                .doc(user.mun)
                .collection("console_users")
                .doc(userInfo.user.uid)
                .get()
                .then(async (snapshot) => {
                    return await db
                        .collection("mun")
                        .doc(user.mun)
                        .get()
                        .then((res) => {
                            // console.log('d',res.data())
                            let mun_data = res.data();
                            let defaultLocation = {
                                lat: mun_data.geo.latitude,
                                lng: mun_data.geo.longitude,
                            };
                            let zoom_level = mun_data.zoom_level;
                            localStorage.setItem(
                                "defaultLocation",
                                JSON.stringify(defaultLocation)
                            );
                            localStorage.setItem("zoom_level", zoom_level);
                            let data = snapshot.data();
                            console.log("data: ", data)
                            data.cui = userInfo.user.uid;
                            return data;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((error) => ({
                    errorCode: error.code,
                    errorMessage: error.message,
                }));
        });
}



// const getVehicleReportsRef = () =>{
//     return db.collection("mun").doc(window.localStorage.mun).collection("reports")
// }

// export const  getReportsOfVehicles = async(datesArray, device)=>{
//     let query;
//     if(!device){
//         query = await getVehicleReportsRef().where("date", "in", datesArray).get();
//     }else{
//         query = await getVehicleReportsRef()
//                 .where("date", "in", datesArray)
//                 .where("device_id", "==", device)
//                 .get();
//     }
//     return query;
// }

export const fetchCommercialAndResidents = async (ward) => {
    let temp = [];
    try {
        const [commercials] = await Promise.all([
            getCommercials(ward)
        ]);
        let temp = [];
        commercials?.docs?.map((eachDoc) => {
            let doc = eachDoc.data();
            temp.push(doc);
        })

        return temp;
    } catch (err) {
        return temp;
    }
}

export function logout() {
    return firebaseAuth
        .signOut()
        .then(() => ({
            success: 1,
            message: "logout",
        }))
        .catch((error) => ({
            errorCode: error.code,
            errorMessage: error.message,
        }));
}


export const getRfidDevicesTracking = async (date,key) => {
    console.log("first",date)
    const startDate = new Date(date);
    const endDate = new Date(date);

    // Set start time to 00:00:00
    startDate.setHours(0, 0, 0, 0);
    // Set end time to 23:59:59
    endDate.setHours(23, 59, 59, 999);

    const epochStartTime = startDate.getTime() / 1000;
    const epochEndTime = endDate.getTime() / 1000;
    console.log("first",date,epochStartTime,epochEndTime)
    const db = firebase.firestore();
    const collectionPath = `rfid_info`;

    return db.collection(collectionPath)
        .where("deviceID", "==", key)
        .where("timestamp", ">=", parseInt(epochStartTime))
        .where("timestamp", "<=", parseInt(epochEndTime))
        .get();
};
export const getClustersData = (ward) => {
        return db.collection("mun")
        .doc(window.localStorage.mun)
        .collection("ward_cluster")
        .doc(ward)
        .collection('clusters')
        .get();
}
export const addCluster = async (data) => {
    console.log('add cluster api',data);
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("ward_cluster")
        .doc(data.ward)
        .collection('clusters')
        .doc(`${data.clusterName}+${data.ward}`)
        .set(data);
}
export const deleteCluster = async (ward,cluster) => {
    console.log('deleteCluster',ward,cluster)
    try {
        await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("ward_cluster")
            .doc(ward)
            .collection('clusters')
            .doc(`${cluster.clusterName}+${ward}`)
            .delete()

        console.log("Documents deleted successfully");
    } catch (error) {
        console.error("Error deleting documents: ", error);
    }             
}
export const updateClusterInHousehold = async (user_id,clusterName) => {
    console.log('updateClusterInHousehold',user_id,clusterName);
    try {
        const querySnapshot = await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("households")
            .where("user_id", "==", user_id)
            .get();
        
        const batch = db.batch();

        querySnapshot.forEach(doc => {
            batch.update(doc.ref, { clusterName });
        });

        await batch.commit();
        console.log("Documents successfully updated!");
    } catch (error) {
        console.error("Error updating documents: ", error);
    }
}
export const updateClusterVehicleTouchPoint = async (clusterName, ward, newPoint) => {
    try {
        await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("ward_cluster")
            .doc(ward)
            .collection('clusters')
            .doc(`${clusterName}+${ward}`)
            .update({vehicleTouchPoint : newPoint});
        
        console.log("Documents successfully updated!");
    } catch (error) {
        console.error("Error updating documents: ", error);
    }
}
export const updateClusterClusterArea = async (clusterName, ward, newArea) => {
    try {
        await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("ward_cluster")
            .doc(ward)
            .collection('clusters')
            .doc(`${clusterName}+${ward}`)
            .update({clusterArea : newArea});
        
        console.log("Documents successfully updated!");
    } catch (error) {
        console.error("Error updating documents: ", error);
    }
}
export const updateClusterInCommercial = async (authUid,clusterName) => {
    try {
        const querySnapshot = await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("commercials")
            .where("authUid", "==", authUid)
            .get();
        
        const batch = db.batch();

        querySnapshot.forEach(doc => {
            batch.update(doc.ref, { clusterName });
        });

        await batch.commit();
        console.log("Documents successfully updated!");
    } catch (error) {
        console.error("Error updating documents: ", error);
    }
}
export const deleteClusterNameFromHouseholds = async (cluster) => {
    try {
        const querySnapshot = await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("households")
            .where("clusterName", "==", cluster.clusterName)
            .get();
        
        const batch = db.batch();

        querySnapshot.forEach(doc => {
            batch.update(doc.ref, { clusterName: "" });
        });

        await batch.commit();
        console.log("Documents successfully updated!");
    } catch (error) {
        console.error("Error updating documents: ", error);
    }
}
export const deleteClusterNameFromCommercials = async (cluster) => {
    try {
        const querySnapshot = await db
            .collection("mun")
            .doc(window.localStorage.mun)
            .collection("commercials")
            .where("clusterName", "==", cluster.clusterName)
            .get();
        
        const batch = db.batch();

        querySnapshot.forEach(doc => {
            batch.update(doc.ref, { clusterName: "" });
        });

        await batch.commit();
        console.log("Documents successfully updated!");
    } catch (error) {
        console.error("Error updating documents: ", error);
    }
}
export const getHouseholdsInCluster = (cluster_id,ward) => {
    console.log('getHouseholdsInCluster',cluster_id)
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("households")
        .where("ward_id","==",ward)
        .where("clusterName", "==", cluster_id)
        .get();
}
export const getCommercialsInCluster = (cluster_id) => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("commercials")
        .where("clusterName", "==", cluster_id)
        .get();
}
export const getAllClusterByWard = (ward) => {
    return db
        .collection("mun")
        .doc(window.localStorage.mun)
        .collection("ward_cluster")
        .doc(ward)
        .collection('clusters')
        .get();
}
export const getUsersByWard = async (ward) => {
    return db
    .collection("mun")
    .doc(window.localStorage.mun)
    .collection("users")
    .where("userType","==","user")
    .where("ward","==",ward)
    .get()
}